import styles from "../AddFundCategoryForm.module.scss";
import { percentageEditor } from "../../../../Helpers/Helpers";
import React from "react";
import {useFormContext} from "react-hook-form";
import {FundCategoryHoldingWeightResult} from "../../../../model/funds/weights/FundWeights";
import {WeightDisplayMode} from "../FundCategoryHoldingsView";
import { useAuth } from "../../../../Hooks/useAuth";

export function HoldingLeaf({
  groupIndex,
  categoryIndex,
  holdingIndex,
  editMode,
  displayMode,
  iterateDisplayMode
}: {
  groupIndex: number,
  categoryIndex: number;
  holdingIndex: number;
  editMode: boolean;
  displayMode: WeightDisplayMode,
  iterateDisplayMode: ()=>void
}) {

  const methods = useFormContext();
  const { darkmode } = useAuth();
  const {register, getValues}  = methods;
  const category = getValues(`groups.${groupIndex}.categories.${categoryIndex}`);
  const holding = getValues(`groups.${groupIndex}.categories.${categoryIndex}.fundCategoryCryptos.${holdingIndex}`);

  if(holding == null){
    return;
  }

  const totalWeight = category?.fundCategoryCryptos.reduce(
    (acc:number, curr: FundCategoryHoldingWeightResult) => acc + (curr.preferredWeight ?? 0),
    0,
  );

  const min = holding.minPercentage ?? 0;
  const max = holding.maxPercentage ?? 0;
  const aim = holding.aimPercentage ?? 0;
  const curTotal= (holding.currentPercentageOfTotal ?? 0) * 100;
  const curCategory= (holding.currentPercentageOfCategory ?? 0) * 100;

  const partOfWeight = (holding.preferredWeight ?? 0) / (totalWeight ?? 1);
  const title = {
    [WeightDisplayMode.category]: `aim: ${percentageEditor(partOfWeight * 100)}`,
    [WeightDisplayMode.total]: `min: ${percentageEditor(min)}, aim: ${percentageEditor(aim)}, max: ${percentageEditor(max)}`,
  }[displayMode];

  const shouldShowRed =  max > 0 && (curCategory < min || curTotal > max);

  const renderWeight = () => {
    switch(displayMode){
      case WeightDisplayMode.category:
        return percentageEditor(curCategory);
      case WeightDisplayMode.total:
        return percentageEditor(curTotal);
    }
  }

  return (
    <div
      className={`${styles.smallFont} w-full`}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <span style={{ color: darkmode ? "white" : "#333333" }}>{holding.ticker}</span>
      <div style={{ paddingRight: "3.3em" }}>
        {editMode ? (
          <>
            <span>Weight:</span>
            <input
              type="number"
              style={{ maxWidth: "4em", marginLeft: "1em", padding: "10px", height: "1.1rem" }}
              className={styles.fundFormInputs}
              {...register(`groups.${groupIndex}.categories.${categoryIndex}.fundCategoryCryptos.${holdingIndex}.preferredWeight`, {valueAsNumber:true})}
            ></input>
          </>
        ) : (
          <span
            title={title}
            style={{
              cursor: "pointer",
              color: shouldShowRed ? "#CF6C00" : darkmode ? "white" : "#333333",
            }}
            onClick={iterateDisplayMode}
          >
            {
              renderWeight()
            }
          </span>
        )}
      </div>
    </div>
  );
}
