import { Link } from "react-router-dom";
import DashboardComponent from "../../Components/DashboardComponent";
import EmptyMessageComponent from "../../Components/EmptyMessageComponent";
import { confirmationAlertDeleteVentureDeal, useDeleteVentureDeal, useVenturesDeals } from "../../query-hooks/ventures-query-hooks/useVentures";
import { useAuth } from "../../Hooks/useAuth";
import styles from './Ventures.module.scss'
import { Table } from "react-bootstrap";
import { tableDateFormatter } from "../../Helpers/Helpers";
import { useState } from "react";
import { VentureDealListViewModel } from "@hodl.nl/hodltradingdesk";
import PaginationAPI from "../../Components/PaginationAPI";

const VenturesDashboard = () => {
  const [ page, setPage ] = useState<number>(1)
  const deals = useVenturesDeals(page)
  const [currentItems, setCurrentItems] = useState<VentureDealListViewModel[]>([]);
  const getCurrentItems = (updatedItems: VentureDealListViewModel[]) => setCurrentItems(updatedItems)
  const { darkmode } = useAuth()
  const headers=[ 'Name', 'Contract Address', 'Contract Date', 'Total Tokens', 'TGE Date', 'End Date', '', '', '' ]
  const deleteVentureDeal = useDeleteVentureDeal()

  const deleteVentureDealById = async (event: React.MouseEvent<HTMLButtonElement>, ventureDealId: string | undefined) => {
    if (ventureDealId) {
      event.stopPropagation()
      try {
        const result = await confirmationAlertDeleteVentureDeal(event, ventureDealId)
        deleteVentureDeal.mutate(result)
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (deals.isSuccess && deals.data.items && deals.data.items.length < 1) {
    return (
      <DashboardComponent title="Ventures Deals" subtitle="Ventures Dashboard" toCreate="/main/ventures/create-deal" buttonMessage="Create a Deal">
        <EmptyMessageComponent message='No Deals have been created' />
      </DashboardComponent>
    )
  }

  if (deals.isSuccess && deals.data?.items) {
    return (
      <DashboardComponent title="Ventures Deals" subtitle="Ventures Dashboard" toCreate="/main/ventures/create-deal" buttonMessage="Create a Deal">
        <Table responsive borderless={true} >
          <thead>
              <tr className={darkmode ? styles.theaderDark : styles.theader}>
                {   headers.map((header, i) => <th key={i}>{ header }</th>)   }
              </tr>
          </thead>

          <tbody>
            { currentItems && currentItems.map((deal) => (
              <tr key={deal.id} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                  <td>{deal.dealName}</td>
                  <td>{deal.contractAddress}</td>
                  <td>{tableDateFormatter(deal.contractDate)}</td>
                  <td>{deal.totalTokens}</td>
                  <td>{tableDateFormatter(deal.tgeDate)}</td>
                  <td>{tableDateFormatter(deal.endDate)}</td>
                  <td>
                    <Link className={styles.editButton} to={`/main/ventures/details/${deal.id}`}>Details</Link>
                  </td>
                  <td>
                    <button className={styles.deleteButton} onClick={(e) => deleteVentureDealById(e, deal.id)}>Delete</button>
                  </td>
              </tr>
            ))}
          </tbody>
        </Table>
        { deals.isSuccess && <PaginationAPI data={deals.data} getCurrentItems={getCurrentItems as (updatedItems: VentureDealListViewModel[]) => void} setPage={setPage} /> }
      </DashboardComponent>
    )
  }
}
export default VenturesDashboard;