import { Button, Col, Row, Table } from "react-bootstrap";
import styles from "./BlockchainNetworks.module.scss";
import React, { useEffect } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { BlockchainNetworkExtensionViewModel } from "./BlockchainNetworksDetails";
import { useFieldArray, useForm } from "react-hook-form";
import { useCheckRoles } from "../../query-hooks/user-query-hooks/useUser";
import { SourceIdInput } from "./SourceIdInput";

export function BlockchainNetworksForm({
  data,
  sources,
  onSubmit,
}: {
  data: BlockchainNetworkExtensionViewModel | null;
  sources: string[];
  onSubmit: (data: BlockchainNetworkExtensionViewModel) => void;
}) {
  const { darkmode } = useAuth();
  const isAllowed = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"]);
  const isAdminOrLeadTrader = isAllowed.data;

  const { control, register, reset, getValues, handleSubmit } =
    useForm<BlockchainNetworkExtensionViewModel>({
      defaultValues: {
        name: "",
        chainID: 0,
        currencySymbol: "",
        exchangeContracts: [],
        rpcUrl: "",
        explorerUrl: "",
        wallets: [],
        sourceIdMappings: [],
        autoImportBalances: false,
      },
    });
  const { fields, append } = useFieldArray({
    control,
    name: "sourceIdMappings",
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
    const existingSourceIdMappings = getValues("sourceIdMappings");
    const missingSources =
      sources.filter(
        (x) => !existingSourceIdMappings.some((y) => y.source === x),
      ) || [];

    for (let source of missingSources) {
      append({ source, sourceId: "" });
    }
  }, [data]);

  const colWidth = 6;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={colWidth}>
          <label
            className={`${darkmode ? styles.inputLabelDark : styles.inputLabel} w-100`}
          >
            <span>Name</span>
            <input
              className={styles.fundFormInputs}
              {...register("name")}
              placeholder={"Name"}
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={colWidth}>
          <label
            className={`${darkmode ? styles.inputLabelDark : styles.inputLabel} w-100`}
          >
            <span>Currency Symbol</span>
            <input
              className={styles.fundFormInputs}
              {...register("currencySymbol")}
              placeholder={"Currency Symbol"}
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={colWidth}>
          <label
            className={`${darkmode ? styles.inputLabelDark : styles.inputLabel} w-100`}
          >
            <span>Chain Id</span>
            <input
              className={styles.fundFormInputs}
              {...register("chainID", { valueAsNumber: true })}
              type={"number"}
              placeholder={"Chain Id"}
            />
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={colWidth}>
          <label
            className={`${darkmode ? styles.inputLabelDark : styles.inputLabel} w-100`}
          >
            <span>RPC URL</span>
            <input
              className={styles.fundFormInputs}
              {...register("rpcUrl")}
              placeholder={"RPC URL"}
            />
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={colWidth}>
          <label
            className={`${darkmode ? styles.inputLabelDark : styles.inputLabel} w-100`}
          >
            <span>Explorer URL</span>
            <input
              className={styles.fundFormInputs}
              {...register("explorerUrl")}
              placeholder={"Explorer URL"}
            />
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={colWidth}>
          <div className={styles.checkbox}>
            <span
              className={darkmode ? styles.inputLabelDark : styles.inputLabel}
            >
              Testnet?
            </span>
            <input
              type={"checkbox"}
              {...register("isTestNet")}
            />
          </div>

          {isAdminOrLeadTrader && (
            <div className={styles.checkbox}>
              <span
                className={darkmode ? styles.inputLabelDark : styles.inputLabel}
              >
                Auto import balances?
              </span>
              <input
                type={"checkbox"}
                {...register("autoImportBalances")}
              />
            </div>
          )}
        </Col>
      </Row>

      <Row className={"mt-2"}>
        <Col xs={colWidth}>
          <h3>Sources</h3>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col xs={colWidth}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Source</th>
                <th>Source ID</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((sourceIdMapping, index) => (
                <tr key={sourceIdMapping.id}>
                  <td>{sourceIdMapping.source}</td>
                  <td>
                    <SourceIdInput
                      source={sourceIdMapping.source}
                      register={register(`sourceIdMappings.${index}.sourceId`)}
                      defaultValue={sourceIdMapping.sourceId}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col xs={colWidth} style={{ alignItems: "end" }}>
          <Button
            className={`${styles.submitButton} float-right`}
            type={"submit"}
          >
            Save
          </Button>
        </Col>
      </Row>
    </form>
  );
}
