import {useMarketDepthServiceState} from "../../query-hooks/admin-query-hooks/useAdmin";
import {Button, ProgressBar} from "react-bootstrap";
import {useCheckRoles} from "../../query-hooks/user-query-hooks/useUser";
import {useRunMarketDepthManually} from "../../query-hooks/market-depth-query-hooks/useMarketDepth";
import styles from "./MarketDepthPage.module.scss";

export function MarketDepthStatusWidget(){
  const isAdmin = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"])
  const status = useMarketDepthServiceState();
  const runMarketDepthMutation = useRunMarketDepthManually();
  const percentDone = status.isSuccess ? status.data.percentDone * 100 : 0;
  const runSyncManually = ()=>{
    runMarketDepthMutation.mutate();
  };

  const buttonDisabled = !status.isSuccess || status.data.running;
  const buttonClass = buttonDisabled ? styles.submitButtonDisabled : styles.submitButton;

    return <div className="d-flex flex-row align-items-center">
      {
        status.isSuccess && status.data.running &&
          <>
          <span>Update status:</span>
          <ProgressBar now={percentDone}
                       className={`flex-grow-1 mx-4 progressColor`}
                       label={`${percentDone.toFixed(2)}%`}>
          </ProgressBar>
        </>
      }
      {
        isAdmin.data &&
        <Button
          className={`${buttonClass} my-3 bgWithColor`}
          disabled={buttonDisabled}
          onClick={runSyncManually}>Sync manually</Button>
      }
    </div>
}