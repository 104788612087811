import { Control, useFormContext } from 'react-hook-form'
import styles from '../Form.module.scss';
import { useAuth } from '../../../../Hooks/useAuth';
import { Controller } from "react-hook-form";

interface Props {
  label?: string,
  registerName: string,
  required: boolean,
  defaultDate?: boolean | string,
  disabled?:boolean,
  control: Control
}

const ControlledDateTime = ({ label, registerName, required, defaultDate, disabled, control }: Props) => {
    const { formState: { errors } } = useFormContext()
    const { darkmode } = useAuth()

    return (
        <>
            { label &&         
                <label className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>
                    { label }
                    { required ? <span className='text-danger'> *</span> : <span className='text-xs italic text-secondary font-light'> Optional</span> }
                </label>
            }
            <Controller
                name={registerName as any}
                control={control}
                rules={{ required }}
                render={({ field }) => (
                <input
                    type="datetime-local"
                    {...field}
                    value={field.value || ""} // Avoid undefined
                    onChange={(e) => field.onChange(e.target.value)}
                    disabled={disabled}
                    className={darkmode ? styles.datetimeDark : styles.datetime}
                />
                )}
            />      
            { errors[registerName]?.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>  }
        </>
    );
};

export default ControlledDateTime;