import { useFormContext } from 'react-hook-form'
import styles from './Form.module.scss';
import { useAuth } from '../../../Hooks/useAuth';

interface Props {
    label?: string
    registerName: string,
    required: boolean,
    disabled? : boolean,
    defaultValue?: any,
}

const NumberInput = ({ label, registerName, required, disabled, defaultValue }: Props) => {
    const { register, formState: { errors } } = useFormContext()
    const { darkmode } = useAuth()

    return (
        <>
            {   label &&
                <>
                <label className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>
                    { label }
                </label>
                { required ? <span className={styles.reqSymbol}> *</span> : <span className='text-xs italic text-secondary font-light'> Optional</span> }
                </>
            }
            <input
                type="number"
                placeholder={label}
                { ...register(registerName, { required: required, valueAsNumber: true }) }
                className={styles.fundFormInputs}
                disabled={disabled}
                min="0"
                step='any'
                autoComplete='off'
                defaultValue={defaultValue}
            />
            { errors[registerName]?.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>  }
        </>
    )
}
export default NumberInput;