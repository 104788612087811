import { BenchmarkCandle } from "./model/benchmarkCandle";
import { periodicReturn } from "./raw/annualizedReturn";
import { IndicatorResult } from "./model/indicatorResult";
import React from 'react';

export function getAverageMonthlyReturnResult(input: BenchmarkCandle[]): IndicatorResult {
  const value = periodicReturn(input, 1);
  return {
    title: "Average Monthly Return",
    value: `${(value * 100).toFixed(2)}%`,
    tooltip: "Average monthly return",
    rawValue: value
  };
}

interface Props {
  input: BenchmarkCandle[];
}

export const AverageMonthlyReturn: React.FC<Props> = ({ input }) => {
  const result = getAverageMonthlyReturnResult(input);
  return <p>{result.value}</p>;
};