import React, { useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import styles from "../Funds/FundDetail.module.scss"
import PaginationAPI from '../../Components/PaginationAPI'
import { useAuth } from '../../Hooks/useAuth'
import { useTransfers, getTransferDate, useDeleteTransfer, confirmationAlertDeleteTransfer } from '../../query-hooks/transfer-query-hooks/useTransfer'
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";
import { Spinner, numberEditor, ErrorLoading } from "../../Helpers/Helpers"
import EmptyMessageComponent from '../../Components/EmptyMessageComponent'
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import FileAttachment from '../../Components/Attachments/FileAttachment'
import TransferFormMutationStateHandler from './TransferFormMutationStateHandler'

const FundTransferDashboardCard = ({ fundId, bookingPeriod, selectedFundClassId }) => {
    const [ page, setPage ] = useState(1)
    const transfers = useTransfers(fundId, bookingPeriod, selectedFundClassId, page)
    const { darkmode } = useAuth()
    const [currentItems, setCurrentItems] = useState();
    const getCurrentItems = (updatedItems) => setCurrentItems(updatedItems)
    const mutation = useDeleteTransfer()
    const [ openFormCreateModalObject, setOpenFormCreateModalObject ] = useState()
    
    const filterTransfers = items => {
        let transferIdsToBeRemoved = []
        const transfersOppositeMatches = items.map((obj, i, arr) => {
            if (!obj.oppositeTransferId || obj.oppositeTransferId === '00000000-0000-0000-0000-000000000000' ) {
                return obj
            }
            if (obj.oppositeTransferId && obj.direction === "Out") {
                const oppositeTransferObj = arr.find(transfer => transfer.id === obj.oppositeTransferId) // Find the opposite transfer in original array
                if (!oppositeTransferObj) return obj // If opposite transfer is not found, return the original object
                obj["oppositeTransfer"] = oppositeTransferObj // Add a copy of the found opposite transfer object as new object property   
                transferIdsToBeRemoved.push(oppositeTransferObj.id) // Add the opposite transfer id to the array of ids to be removed
                return obj // Return the original object with the new opposite transfer property
            }
            return obj // Return the original object that has an opposite transfer id and direction is "In"
        })
        // if Transfer Ids To Be Removed array length is greater than 0, filter the array of transfers by each transfer id to be removed, otherwise return the original array
        if (transferIdsToBeRemoved.length > 0) { 
            return transfersOppositeMatches.filter(transfer => !transferIdsToBeRemoved.includes(transfer.id)) 
        } 
        return transfersOppositeMatches
    }
    
    if (transfers.isSuccess && transfers.data.items.length < 1) {
        return (
            <>
                <Row className="mb-4">
                    <Col lg={12} className={styles.addContainer}>
                        Create a Transfer
                        <p className={styles.addIcon} onClick={() => setOpenFormCreateModalObject({ fundId: selectedFundClassId ? selectedFundClassId : fundId })}>+</p>
                    </Col>
                </Row>
                <EmptyMessageComponent message="No Transfers Created" />
                {/* Display Form Create or Edit Modal */}
                {   openFormCreateModalObject && 
                    <TransferFormMutationStateHandler 
                        openFormCreateModalObject={openFormCreateModalObject} 
                        setOpenFormCreateModalObject={setOpenFormCreateModalObject}
                        bookingPeriod={bookingPeriod}
                    />  
                }
            </>
        )
    }
    return (
        <>
            {/* Display Form Create or Edit Modal */}
            {   openFormCreateModalObject && 
                <TransferFormMutationStateHandler 
                    openFormCreateModalObject={openFormCreateModalObject} 
                    setOpenFormCreateModalObject={setOpenFormCreateModalObject}
                    bookingPeriod={bookingPeriod}
                />  
            }
            <div className={`${styles.holdingsHeader} ${darkmode ? styles.dark : ''}`} style={{height:"3rem"}}>
                <Row className="mb-4">
                    <Col lg={12} className={styles.addContainer}>
                        Create a Transfer
                        <p className={styles.addIcon} onClick={() => setOpenFormCreateModalObject({ fundId: selectedFundClassId ? selectedFundClassId : fundId })}>+</p>
                    </Col>
                </Row>
            </div>
            <Table borderless={true}>
            <thead className={`${styles.thead} ${darkmode ? styles.dark : ''}`} style={{top: "2.5rem"}}>
                <tr className={styles.theader} >
                    <th></th>
                    <th></th>
                    <th>Transaction Type</th>
                    <th>Currency</th>  
                    <th>Transfer Amount</th> 
                    <th>Transfer Fee</th> 
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
                { currentItems && filterTransfers(currentItems).map((transfer, i) => (
                    <tbody key={transfer.id} style={transfer?.oppositeTransfer ? (i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}) : {}}>
                        <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips }>
                            <td style={{width:"12%"}}>
                                <div className={styles.tableDate}>
                                    <span>{ getTransferDate(transfer.dateTime).day }</span><span> { getTransferDate(transfer.dateTime).month }</span>
                                    <p>{ getTransferDate(transfer.dateTime).year }</p>
                                </div>
                            </td>
                            <td>{ transfer.direction === "In" ?
                                <div className={styles.tooltip}>
                                    <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                    <span className={styles.tooltiptext}>{transfer.direction}</span>
                                </div> 
                                    : 
                                <div className={styles.tooltip}>
                                    <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                    <span className={styles.tooltiptext}>{transfer.direction}</span>
                                </div>
                                }
                            </td>                        
                            <td>{ transfer.transactionType }</td>
                            <td>{ transfer.holding.displayName }</td>
                            <td>{ numberEditor(transfer.transferAmount) }</td>
                            <td>{ numberEditor(transfer.transferFee) }</td>
                            <td><FileAttachment table="Transfers" recordId={transfer.id} /></td>
                            <td title="Edit Transfer">
                                <AiOutlineEdit className={styles.icons} onClick={() => setOpenFormCreateModalObject({ fundId: selectedFundClassId ? selectedFundClassId : fundId, transferId: transfer.id })} />
                            </td>
                            <td title="Delete Transfer" onClick={e => confirmationAlertDeleteTransfer(e, transfer.id, mutation)}>
                                <AiOutlineDelete className={styles.icons} />
                            </td>
                        </tr>
                        {
                            transfer.oppositeTransfer && 
                            <tr className={ darkmode ? styles.tableStripsDark : styles.tableStrips } style={i % 2 === 0 ? {background:"#2F4858"} : {background:"#4F7A7F"}}>
                                <td style={{background:"transparent"}}></td>
                                <td>
                                    {   transfer.oppositeTransfer.direction === "In" ?
                                            <div className={styles.tooltip}>
                                                <BiLeftArrowCircle style={{color: "#6D9E93", fontSize: "30px"}} /> 
                                                <span className={styles.tooltiptext}>{transfer.oppositeTransfer.direction}</span>
                                            </div> 
                                                : 
                                            <div className={styles.tooltip}>
                                                <BiRightArrowCircle style={{color: "#CD5C5C", fontSize: "30px"}} />
                                                <span className={styles.tooltiptext}>{transfer.oppositeTransfer.direction}</span>
                                            </div>
                                    }
                                </td>
                                <td>{ transfer.oppositeTransfer.transactionType }</td>
                                <td>{ transfer.oppositeTransfer.holding.displayName }</td>
                                <td>{ numberEditor(transfer.oppositeTransfer.transferAmount) }</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                    </tbody>
                ))}
            </Table>
            <PaginationAPI data={transfers.isSuccess && transfers.data} getCurrentItems={getCurrentItems} setPage={setPage} /> 
            { transfers.isLoading && <Spinner /> }{ transfers.isError && <ErrorLoading error={transfers.error} /> }
        </>
    )
  }
export default FundTransferDashboardCard