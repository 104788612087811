import React, {useEffect, useState} from 'react';
import { useManualWalletBalanceUpdate, confirmAndUpdateBalance } from '../../../../query-hooks/balance-reconciliation-hooks/useBalanceReconciliation';
import { ReconciliationListViewModel } from '@hodl.nl/hodltradingdesk';
import styles from './ReconciliationTable.module.scss';
import { useAuth } from '../../../../Hooks/useAuth';
import useWallets, { useNetworks } from '../../../../query-hooks/wallet-query-hook/useWallet';
import Swal from 'sweetalert2';

interface UpdateWalletBalanceRowProps {
    fundId: string;
    data: ReconciliationListViewModel;
    bookingPeriod: string;
}

export function UpdateWalletBalanceRow({ data, fundId, bookingPeriod }: UpdateWalletBalanceRowProps) {
    const { darkmode } = useAuth();
    const mutation = useManualWalletBalanceUpdate();
    const [selectedWallet, setSelectedWallet] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState<string|undefined>(undefined);
    const [newBalance, setNewBalance] = useState('');
    const wallets = useWallets(fundId);
    const networks = useNetworks();

    const exchangeAccount = wallets.data?.items?.filter(x => x.address === selectedWallet)[0]?.exchangeAccount
    const showNetworks = exchangeAccount == null || exchangeAccount.exchange?.isDEX;

    useEffect(()=>{
        if(!showNetworks){
            setSelectedNetwork(undefined);
        }
    }, [showNetworks, setSelectedNetwork, selectedNetwork])

    if (!data.holding?.cryptoCurrency?.id || wallets.isLoading || wallets.isError || networks.isLoading || networks.isError) {
        return null;
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Validation checks
        if (!selectedWallet) {
            Swal.fire({
                title: 'Error',
                text: 'Please select a wallet',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        const wallet = wallets.data?.items?.filter(x => x.address == selectedWallet)[0];
        if(showNetworks && !selectedNetwork){
            Swal.fire({
                title: 'Error',
                text: 'Please select a network',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        if (!newBalance) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a balance',
                icon: 'error',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        const details = {
            walletAddress: selectedWallet,
            cryptoCurrencyId: data.holding!.cryptoCurrency!.id!,
            blockchainNetworkId: selectedNetwork ?? undefined,
            newBalance: newBalance,
            bookingPeriod
        };

        confirmAndUpdateBalance(details, mutation);
    };

    const usedWallets = data.walletBalances?.filter(x => x.balance != 0).map(x => x.address) ?? [];
    const validWallets = wallets.data!.items?.filter(wallet => !usedWallets.includes(wallet.address)) ?? [];

    return (
        <>
            <tr className={`${styles.detailRow} ${darkmode ? styles.dark : ''}`}>
                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                    <span className={styles.label}>Add Wallet Balance</span>
                </td>
                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                    <select
                        value={selectedWallet}
                        onChange={(e) => setSelectedWallet(e.target.value)}
                        className={`${styles.select} ${darkmode ? styles.dark : ''}`}
                    >
                        <option value="">Select Wallet</option>
                        {validWallets.map(wallet => (
                            <option key={wallet.address} value={wallet.address!}>
                                {wallet.description}
                            </option>
                        ))}
                    </select>
                </td>
                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                    {showNetworks && <select
                        value={selectedNetwork}
                        onChange={(e) => setSelectedNetwork(e.target.value)}
                        className={`${styles.select} ${darkmode ? styles.dark : ''}`}
                    >
                        <option value="">Select Network</option>
                        {networks.data!.items!.filter(x => !x.isTestNet).map(network => (
                            <option key={network.id} value={network.id!}>
                                {network.name}
                            </option>
                        ))}
                    </select>}
                </td>
                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                    <input
                        type="number"
                        step="any"
                        value={newBalance}
                        onChange={(e) => setNewBalance(e.target.value)}
                        placeholder="New Balance"
                        className={`${styles.input} ${darkmode ? styles.dark : ''}`}
                    />
                </td>
                <td></td>
                <td className={`${styles.cell} ${darkmode ? styles.dark : ''}`}>
                    <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={!selectedWallet || !newBalance}
                        className={`${styles.updateButton} ${darkmode ? styles.dark : ''}`}
                    >
                        Update
                    </button>
                </td>
            </tr>
        </>
    );
}
