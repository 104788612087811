import styles from "../Ventures.module.scss";
import { VentureDealDetailViewModel } from "@hodl.nl/hodltradingdesk";
import { Table } from "react-bootstrap";
import { useAuth } from "../../../Hooks/useAuth";
import { localeString } from "../../../Helpers/Helpers";

interface Props {
  data?: VentureDealDetailViewModel | undefined
}

const VestingModule = ({ data }: Props) => {
    const { darkmode } = useAuth()

    if (data && data.vestingSchedules) {
        return (
            <div className={styles.cardTableDark} style={{minHeight: '25rem', padding:'2rem'}}>
                <h2 className={styles.subTitle} style={{display:"inline-block", margin:"0 0 1.5rem 1rem"}}>Vesting Structure</h2>
                <Table>
                    <thead>
                        <tr className={darkmode ? styles.theaderDark : styles.theader}>
                            <th>Period</th>
                            <th>Unlocks %</th>
                            <th>Unlocks</th>
                            <th>Unlocks Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   data.vestingSchedules.map((vesting, index) => (
                                <tr key={index} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                                    <td> { vesting.period }</td>
                                    <td> { vesting.unlockPercentage }%</td>
                                    <td> { vesting.unlockAmount }</td>
                                    <td> { localeString(vesting.unlockDateTime) }</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
};
export default VestingModule;