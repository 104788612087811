import { BenchmarkCandle } from "../model/benchmarkCandle";
import { periodicReturn } from "./annualizedReturn";
import { annualizedVolatility } from "./annualizedVolatility";

/**
 * Calculates the Sharpe ratio for a series of benchmark values
 * The Sharpe ratio is a measure of risk-adjusted return, comparing investment returns
 * above the risk-free rate to the standard deviation of those returns
 * @param weightedSums Array of benchmark candles with normalized values
 * @param riskFreeRate Annual risk-free rate (default: 0.03 or 3%)
 * @returns Sharpe ratio or Infinity/0 for special cases
 */
export function sharpeRatio(weightedSums: BenchmarkCandle[], riskFreeRate: number = 0.03) {
    if (weightedSums.length < 2) {
        return 0;
    }

    if (riskFreeRate < 0) {
        throw new Error("Risk-free rate cannot be negative");
    }

    const excessReturn = periodicReturn(weightedSums) - riskFreeRate;
    const volatility = annualizedVolatility(weightedSums);

    if (volatility === 0) {
        return excessReturn > 0 ? Infinity : 0;
    }

    return excessReturn / volatility;
}