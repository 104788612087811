import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import DashboardComponent from "../../../Components/DashboardComponent";
import { useFundHolding, useEditFundHolding, useFund } from "../../../query-hooks/fund-query-hooks/useFund"
import FundsHoldingFormEdit from './FundsHoldingFormEdit';
import { useCheckRoles } from "../../../query-hooks/user-query-hooks/useUser"
import ForbiddenMessage from '../../../Components/ForbiddenMessage';

const FundsHoldingEdit = () => {
    const { fundId, holdingId } = useParams()
    const fund = useFund(fundId)
    const navigate = useNavigate();
    const holding = useFundHolding(fundId, holdingId)
    const mutation = useEditFundHolding()
    const onSubmit = data => mutation.mutate({fundId: fundId, holdingId: holdingId, holdingEdit: data })
    const [ isVentureCapital, setIsVentureCapital ] = useState(false)
    useEffect(() => { mutation.isSuccess && navigate(-1)}, [mutation])

    // Check if fund is Venture Capital
    useEffect(() => {
        if (fund.isSuccess && fund.data.fundType.toLowerCase() === 'venturecapital') {
            setIsVentureCapital(true)
        }
    }, [ fund ])
    

    // Block to restrict view only to Admin User and lead traders
    const isAdmin = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"])
    if (isAdmin.isSuccess && isAdmin.data === false) {
        return <ForbiddenMessage />
    }

    return (
        <DashboardComponent title={`${fund.isSuccess && fund.data.fundName} Fund`} subtitle="Edit a holding">
            { holding.isSuccess && <FundsHoldingFormEdit onSubmit={onSubmit} data={holding.data} isVentureCapital={isVentureCapital} /> }
        </DashboardComponent>
    );
};
export default FundsHoldingEdit