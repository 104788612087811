import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../Hooks/useAuth';
import styles from './Ventures.module.scss'
import { Link, useParams } from 'react-router-dom';
import { useVentureDeal } from '../../query-hooks/ventures-query-hooks/useVentures';
import DevaluationModule from './Devaluations/DevaluationModule';
import { tableDateFormatter } from '../../Helpers/Helpers';
import VestingModule from './Vestings/VestingModule';

const VenturesDetail = () => {
    const { darkmode } = useAuth()
    const { ventureDealId } = useParams()
    const { isSuccess, data } = useVentureDeal(ventureDealId)
    
    return (
        <>
            <h5 className={darkmode ? styles.subTitleDark : styles.subTitle}>Ventures Deals</h5>
            <h2 className={darkmode ? styles.mainTitleDark : styles.mainTitle}>Details</h2>
            <div className='d-flex justify-content-end gap-3'>
                <Link to={`/main/ventures`} className={styles.createButton}>Back to Dashboard</Link>
                <Link to={`/main/ventures/edit-deal/${ventureDealId}`} className={styles.createButton}>Edit Deal</Link>
            </div>
            <Row>
                <Col xl={12}>
                    {   isSuccess &&
                        <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:'2rem'}}>
                            <Row className='my-2'>
                                <Col xl={3} className={styles.metadata}><h4>Deal Name</h4><h5>{ data?.dealName }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>Total Tokens</h4><h5>{ data?.totalTokens }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>Valuation Type</h4><h5>{ data?.valuationType }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>Contract Address</h4><h5>{ data?.contractAddress }</h5></Col>
                            </Row>
                            <Row className='my-2'>
                                <Col xl={3} className={styles.metadata}><h4>Contract Date</h4><h5>{ tableDateFormatter(data?.contractDate) }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>TGE Date</h4><h5>{ tableDateFormatter(data?.tgeDate) }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>Activation Date</h4><h5>{ tableDateFormatter(data?.activationDate) }</h5></Col>
                                <Col xl={3} className={styles.metadata}><h4>End Date</h4><h5>{ tableDateFormatter(data?.endDate) }</h5></Col>
                            </Row>
                        </div>
                    }
                </Col>
            </Row>
            <Row>
                <Col xl={6}>
                    { ventureDealId && <DevaluationModule data={data} /> }
                </Col>
                <Col xl={6}>
                    { ventureDealId && <VestingModule data={data} /> }
                </Col>
            </Row>
        </>
    )
}
export default VenturesDetail;