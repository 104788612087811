import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import DashboardComponent from "../../../Components/DashboardComponent";
import { useCreateFundHolding, useFund } from "../../../query-hooks/fund-query-hooks/useFund"
import FundsHoldingFormStateHandler from './FundsHoldingFormState/FundsHoldingFormStateHandler';
import { useCheckRoles } from "../../../query-hooks/user-query-hooks/useUser"
import ForbiddenMessage from '../../../Components/ForbiddenMessage';
import { simpleSuccessfulMessage } from '../../../Helpers/Helpers';

const FundsHoldingCreate = ({ closeModalMode, transferModalFundId }) => {
    const { fundId } = useParams()
    const [ isVentureCapital, setIsVentureCapital ] = useState(false)
    const fund = useFund(fundId)
    const navigate = useNavigate();
    const mutation = useCreateFundHolding()

    const onSubmit = data => {
        if (data.ventureDealId === "") {
            data.ventureDealId = null
        }
        mutation.mutate({holding: data, fundId: transferModalFundId ? transferModalFundId : fundId })    
    }

    useEffect(() => {
        if (mutation.isSuccess && closeModalMode) {
            simpleSuccessfulMessage("The Holding has been created succesfully")
            return closeModalMode(false)
        }
        mutation.isSuccess && navigate(-1)
    }, [mutation])

    // Check if fund is Venture Capital
    useEffect(() => {
        if (fund.isSuccess && fund.data.fundType.toLowerCase() === 'venturecapital') {
            setIsVentureCapital(true)
        }
    }, [ fund ])

    // Block to restrict view only to Admin User and lead traders
    const isAdmin = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"])
    if (isAdmin.isSuccess && isAdmin.data === false) {
        return <ForbiddenMessage />
    }

    // Modal that pops only when modal is open, triggered from Transfer Form
    if (closeModalMode) {
        return (
            <div className='text-center'>
                <FundsHoldingFormStateHandler onSubmit={onSubmit} isVentureCapital={isVentureCapital} />
            </div>
        ) 
    }

    // Page that shows only when link at FundHoldingsDashboard Card is triggered
    return (
        <DashboardComponent title={`${fund.isSuccess && fund.data.fundName} Fund`} subtitle="Create a holding">
            <FundsHoldingFormStateHandler onSubmit={onSubmit} isVentureCapital={isVentureCapital} />
        </DashboardComponent>
    );
};
export default FundsHoldingCreate