/**
 * A reusable chart component for displaying market depth data
 * Handles the visualization of market depth data using recharts
 */
import {CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {numberEditor, percentageEditor} from "../../../Helpers/Helpers";

interface ChartDataPoint {
  x: number;
  y: number;
}

interface Props {
  data: ChartDataPoint[];
  referenceLine: number | undefined;
  darkmode: boolean;
  height?: number;
  width?: string;
}

const DateFormatter = (date: number): string => {
  var d = new Date(date);
  return d.getFullYear() + "-"
    + ((d.getMonth() + 1).toString().length === 1 ? '0' : '') + (d.getMonth() + 1) + "-"
    + (d.getDate().toString().length === 1 ? '0' : '') + d.getDate();
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{payload: ChartDataPoint}>;
  label?: number;
  darkmode: boolean;
}

const CustomTooltip = ({active, payload, label, darkmode}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <p style={{color: darkmode ? 'white' : 'black'}}
           className="label">{`${DateFormatter(label!)}: ${numberEditor(payload[0].payload.y)}`}</p>
      </div>
    );
  }
  return null;
};

export default function MarketDepthChart({
  data,
  referenceLine,
  darkmode,
  height = 400,
  width = "95%"
}: Props) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: "4em", width: "100%", height }}>
      <ResponsiveContainer width={width} height={height}>
        <LineChart data={data}>
          <Line
            type="monotone"
            dataKey="y"
            stroke={darkmode ? "white" : "black"}
            strokeWidth={2}
            dot={true}
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey={"x"}
            domain={[data[0].x, data[data.length - 1].x]}
            scale="time"
            type="number"
            tickFormatter={DateFormatter}
            tick={{
              fill: "white",
            }}
          />
          <YAxis
            domain={[0, (datamax: number) => datamax * 1.5]}
            tickFormatter={numberEditor}
            dataKey={"y"}
            tick={{
                fill: "white",
            }}
          />
          <Tooltip content={<CustomTooltip darkmode={darkmode} />} />
          {referenceLine && (
            <ReferenceLine
              y={referenceLine}
              stroke={"gold"}
              strokeWidth={2}
              label={{
                value: percentageEditor(referenceLine),
                position: "top",
                fill: darkmode ? "white" : "black",
              }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}