import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'
import styles from "./WalletForm.module.scss";
import { useExchangeAccountsSelect } from "../../query-hooks/exchange-query-hooks/useExchange"
import { useAuth } from '../../Hooks/useAuth';

const WalletForm = ({ onSubmit, data }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm( { defaultValues: {exchangeAccountId: null }} );
    const allExchangeAccounts = useExchangeAccountsSelect()
    const { darkmode } = useAuth()

    useEffect(() => { 
        data && reset({ address: data.address, description: data.description, exchangeAccountId: !!data.exchangeAccount ? data.exchangeAccount.id : null });
    }, [data, allExchangeAccounts.isSuccess]);

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                { !data && 
                    <Row className="d-flex justify-content-center">
                        <Col lg={6} md={12} className="py-3">
                            <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter an Address</h6>
                            <input className={styles.fundFormInputs} placeholder="Address" {...register("address", { required: true, maxLength: 64 })} />
                            {errors.address && errors.address.type === "required" && (<span className={styles.formErrorNotice}>Address is required</span>)}
                            {errors.address && errors.address.type === "maxLength" && (<span className={styles.formErrorNotice}>Max length of 64 characters exceeded</span>)}
                        </Col>
                    </Row>
                }
                <Row className="d-flex justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Description</h6>
                        <input className={styles.fundFormInputs} placeholder="Description (Optional)" {...register("description")} />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={6} md={12} className="py-3">
                        <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Choose an Exchange Account</h6>
                        <select className={darkmode ? `${styles.selectInputDark}` : `${styles.selectInput} rounded-pill`} {...register("exchangeAccountId") } > 
                            <option value={1}>{ data ? "No exchange account" : "Select an Exchange Account (Optional)" }</option>
                            {allExchangeAccounts.isSuccess && allExchangeAccounts.data.map(exchangeAccount => (
                                <option key={exchangeAccount.id} value={exchangeAccount.id}>{exchangeAccount.name}</option>
                            ))} 
                        </select>                    
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={4} md={12}>
                        <input className={styles.submitButton} type="submit" />
                    </Col>
                </Row>
                <Row className="d-flex text-center mt-2 mb-5">
                    <Link className={styles.backLink} to="/main/wallets">Back</Link>
                </Row>
            </Form>
        </>
    )
}
export default WalletForm