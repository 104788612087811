import { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom'
import styles from "../FundForm.module.scss";
import { useAuth } from "../../../Hooks/useAuth";
import { useFundLayers } from '../../../query-hooks/fund-query-hooks/useFund';
import { useCorrectBalanceTransfer } from "../../../query-hooks/transfer-query-hooks/useTransfer"
import { useVenturesDeals } from '../../../query-hooks/ventures-query-hooks/useVentures';

const FundsHoldingFormEdit = ({data, onSubmit, isVentureCapital }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [ decimals, setDecimals ] = useState(null)
    const [ correctionButton, setCorrectionButton ] = useState(false)
    const balanceCorrection = useCorrectBalanceTransfer()
    const navigate = useNavigate()
    const { darkmode } = useAuth();
    const { fundId } = useParams()
    const layers = useFundLayers(fundId)
    const ventureDeals = useVenturesDeals()
    
    useEffect(() => { data && reset(
        { 
            startBalance: data.startBalance, 
            endBalance: data.endBalance, 
            startUSDPrice: data.startUSDPrice, 
            endUSDPrice: data.endUSDPrice, 
            layerIndex: data.layerIndex,
            ventureDealId: data.ventureDealId
        }
    )}, [data]);
    
    useEffect(() => { 
        if (data.cryptoCurrency) {
            return setDecimals(data.cryptoCurrency.decimals) 
        }
        if (data.currency) {
            return setDecimals(data.currency.decimals) 
        }
        setDecimals(null)
    }, [data])

    useEffect(() => {  // Effect to evaluate if correction is needed and display correction button
        const val = data && data.endBalance * data.startUSDPrice 
        if (val === 0) {
            return setCorrectionButton(false)
        }
        if (val > -1 && val < 1 ) {
            return setCorrectionButton(true)
        }
    }, [data])

    useEffect(() => { balanceCorrection.isSuccess && navigate(-1) }, [balanceCorrection])
    const onHandleCorrection = e => { //Function that triggers hook to post automated transfer
        e.preventDefault()
        balanceCorrection.mutate(data)
    }

    return (
        <>
        <h3 className={styles.mainTitle}>{ data.displayName }</h3>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="justify-content-center">
                <Col xl={4} md={8} className="py-1">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Start Balance</h6> 
                    <input 
                        className={styles.fundFormInputs} 
                        type="number" 
                        onWheel={e => e.currentTarget.blur()} 
                        step="any" 
                        placeholder="Start Balance"
                        {...register("startBalance", { required: true, valueAsNumber: true,  }) }
                    />
                    {errors.startBalance && errors.startBalance.type === "pattern" && (<span className={styles.formErrorNotice}>You have exceeded the maximum of decimals for this currency</span>)}
                    {errors.startBalance && errors.startBalance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    { decimals && <p className={darkmode ? styles.decimalsNoticeDark : styles.decimalsNotice}><em>{ `*Max. of ${decimals} decimals for this currency` }</em></p> }
                </Col>
                <Col xl={4} md={8} className="py-1">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter End Balance</h6> 
                    <div className={styles.inputWithButton}>
                        <input 
                            className={styles.fundFormInputs} 
                            type="number" 
                            onWheel={e => e.currentTarget.blur()} 
                            step="any" 
                            placeholder="End Balance" 
                            {...register("endBalance", { required: true, valueAsNumber: true })} />
                        { correctionButton && <span className={styles.tooltiptext}>Create a correction to set the balance to 0</span> }
                        { correctionButton && <button onClick={e => onHandleCorrection(e)}>Clear Balance</button> }
                        {errors.endBalance && errors.endBalance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={4} md={8} className="py-1">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter a Start USD Price</h6>
                    <input 
                        className={styles.fundFormInputs} 
                        type="number" 
                        onWheel={e => e.currentTarget.blur()} 
                        step="any" 
                        placeholder="Start USD Price" 
                        {...register("startUSDPrice", { required: true, valueAsNumber: true, pattern: /^(\d+(?:[\.\,]\d{0,8})?)$/i })} />
                    {errors.startUSDPrice && errors.startUSDPrice.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                    {errors.startUSDPrice && errors.startUSDPrice.type === "pattern" && (<span className={styles.formErrorNotice}>Max decimal places: 8</span>)}
                </Col>
                <Col xl={4} md={8} className="py-1">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Enter End USD Price</h6> 
                    <input 
                        className={styles.fundFormInputs} 
                        type="number" 
                        onWheel={e => e.currentTarget.blur()} 
                        step="any" 
                        placeholder="End USD Price" 
                        {...register("endUSDPrice", { required: true, valueAsNumber: true })} 
                    />
                    {errors.startBalance && errors.startBalance.type === "required" && (<span className={styles.formErrorNotice}>This field is required</span>)}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={8} md={8} className="py-4">
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Layer</h6>
                    <select className={styles.selectInput} {...register("layerIndex", { required: true, valueAsNumber: true })} defaultValue={0}> 
                        <option className={styles.selectOption} value={0} >Select a Layer</option>
                        { layers.isSuccess && layers.data.map(layer => (
                            <option key={layer.layerIndex} value={layer.layerIndex} className={styles.selectOption}>{layer.name}</option>
                            ))} 
                    </select>
                </Col>
            </Row>

            {/* If Venture Capital Fund and Crypto is locked, show Venture Deal */}
             {  (isVentureCapital && data.cryptoCurrency && data.cryptoCurrency?.isLocked && data.cryptoCurrency.isLocked === true) &&
                <>
                    <h6 className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>Select a Venture Deal</h6>
                    <Row className="justify-content-center mb-5">
                        <Col xl={8} md={8} className="py-1">
                            <select className={styles.selectInput} {...register("ventureDealId", { required: false })} defaultValue=""> 
                            <option className={styles.selectOption} value="" >Select a Venture Deal</option>
                            { ventureDeals.isSuccess && ventureDeals.data.items.map(deal => (
                                <option key={deal.id} value={deal.id} className={styles.selectOption}>{deal.dealName}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                </>
            }                
            <Row className="d-flex justify-content-center">
                <Col xl={4} md={6} xs={12}>
                    <input className={styles.submitButton} type="submit" /> 
                </Col>
            </Row>
            <Row className="d-flex text-center mt-2 mb-5">
                <h5 className={styles.backLink} onClick={() => navigate(-1)}>Back</h5>
            </Row>
        </Form>
        </>
    )
}
export default FundsHoldingFormEdit