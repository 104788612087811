import { useFormContext } from 'react-hook-form';
import styles from './Form.module.scss';
import { useAuth } from '../../../Hooks/useAuth';
import { Col } from 'react-bootstrap';

interface Props {
    label?: string
    registerName: string,
    required: boolean,
    disabled? : boolean
}

const TextInput = ({ label, registerName, required, disabled }: Props) => {
    const { register, formState: { errors } } = useFormContext()
    const { darkmode } = useAuth()

    return (
        <>
            {   label && 
                    <label className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>
                        { label }
                        { required ? <span className={styles.reqSymbol}> *</span> : <span> Optional</span> }
                    </label>
            }
            <input
                type="text"
                placeholder={label}
                className={styles.fundFormInputs}
                { ...register(registerName, { required: required }) }
                disabled={disabled}
                autoComplete='off'
            />
            { errors[registerName]?.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>  }
        </>
    )
}
export default TextInput;