import { BenchmarkCandle } from "../model/benchmarkCandle";

// periods can be 1 for monthlyreturn or 12 for annual return
export function periodicReturn(weightedSums: BenchmarkCandle[], periods = 12) {
    if (weightedSums.length < 2) {
        return 0;
    }

    // Calculate periodic returns
    const periodicReturns = [];
    for (let i = 1; i < weightedSums.length; i++) {
        const previous = weightedSums[i - 1].normalizedValue;
        const current = weightedSums[i].normalizedValue;
        const periodicReturn = (current - previous) / previous;
        periodicReturns.push(periodicReturn);
    }

    // Calculate average periodic return
    const averagePeriodicReturn = periodicReturns.reduce((sum, return_) => sum + return_, 0) 
        / periodicReturns.length;

    // Annualize the return
    return (1 + averagePeriodicReturn) ** periods - 1;
}
