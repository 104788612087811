/**
 * Displays historical market depth data with filtering and timeframe controls
 * Manages data fetching and state while delegating chart rendering to MarketDepthChart
 */
import {useMarketDepthHistory} from "../../../query-hooks/market-depth-query-hooks/useMarketDepth";
import {useEffect, useState} from "react";
import {useAuth} from "../../../Hooks/useAuth";
import styles from "./MarketDepth.module.scss";
import MarketDepthChart from "./MarketDepthChart";

export default function HistoryChart({id}) {
  const {darkmode} = useAuth();
  const [data, setData] = useState([]);
  const [filter, updateFilter] = useState(-2);
  const [timeframe, setTimeframe] = useState(null);
  const history = useMarketDepthHistory(id, timeframe);

  useEffect(() => {
    if (history.isSuccess) {
      let toSet = [];
      for (let item of history.data.history) {
        toSet.push({
          x: new Date(item.timeStamp).getTime(),
          y: item.marketDepths.filter(x => x.pct === filter)[0].usd
        });
      }
      setData(toSet);
    }
  }, [history.data, filter])

  var filterRows = [];
  for (var i = -10; i <= 10; i++) {
    if (i !== 0) {
      filterRows.push(<option key={i} value={i}>{i}%</option>);
    }
  }

  if(data.length === 0){
    return <h3>No data available</h3>
  }

  function setStartTime(timeframe){
    var start = new Date();
    switch(timeframe){
      case "All":
        start = null;
        break;
      case "1Y":
        start.setTime(start.getTime() - 365 * 24 * 3600 * 1000);
        break;
      case "6m":
        start.setTime(start.getTime() - 0.5 * 365 * 24 * 3600 * 1000);
        break;
      case "3m":
        start.setTime(start.getTime() - 0.25 * 365 * 24 * 3600 * 1000);
        break;
      case "1m":
        start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
        break;
    }
    setTimeframe(start);
  }

  if (data.length > 0) {
    return (
      <>
        <table>
          <tbody>
            <tr>
              <td>
                <label className={["h-full", "text-center"]}>Percentage</label>
              </td>
            </tr>
            <tr>
              <td>
                <select
                  className={
                    darkmode ? styles.selectInputDark : styles.selectInput
                  }
                  onChange={(evt) => updateFilter(Number(evt.target.value))}
                  value={filter}
                >
                  {filterRows}
                </select>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("1Y")}
                >
                  1Y
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("6m")}
                >
                  6m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("3m")}
                >
                  3m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("1m")}
                >
                  1m
                </button>
              </td>
              <td>
                <button
                  className={styles.submitButton}
                  type={"button"}
                  onClick={() => setStartTime("All")}
                >
                  All
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <MarketDepthChart
          data={data}
          darkmode={darkmode}
        />
      </>
    );
  }
}