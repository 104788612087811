/**
 * Displays historical fund depth data using the MarketDepthChart component
 * Handles data fetching and transformation for fund liquidity history
 */
import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import styles from "./MarketDepth.module.scss";
import MarketDepthChart from "./MarketDepthChart";
import { useFundDepthHistory } from "../../../query-hooks/market-depth-query-hooks/useMarketDepth";
import { LiquidatableFundBalance } from "@hodl.nl/hodltradingdesk";

interface Props {
  fundId: string;
}

interface ChartDataPoint {
  x: number;
  y: number;
}

export default function FundDepthHistoryChart({ fundId }: Props) {
  const { darkmode } = useAuth();
  const [data, setData] = useState<ChartDataPoint[]>([]);
  const [timeframe, setTimeframe] = useState<Date>(() => {
    const start = new Date();
    start.setTime(start.getTime() - 365 * 24 * 3600 * 1000); // 1 year ago
    return start;
  });
  const history = useFundDepthHistory(fundId, timeframe);

  useEffect(() => {
    if (history.isSuccess && Array.isArray(history.data)) {
      const validData = history.data.filter(
        (item): item is Required<LiquidatableFundBalance> =>
          item.timestamp !== undefined && item.partLiquidatable !== undefined
      );
      
      const toSet = validData
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
        .map(item => ({
          x: new Date(item.timestamp).getTime(),
          y: item.partLiquidatable * 100 // Convert to percentage
        }));
      setData(toSet);
    }
  }, [history.data]);

  if (!history.isSuccess) {
    return <h3>Loading...</h3>;
  }

  if (!Array.isArray(history.data) || history.data.length === 0) {
    return <h3>No data available</h3>;
  }

  if (data.length === 0) {
    return <h3>Processing data...</h3>;
  }

  function setStartTime(timeframe: "All" | "1Y" | "6m" | "3m" | "1m"): void {
    let start = new Date();
    switch(timeframe) {
      case "All":
        start.setFullYear(2000); // Use a very old date to show all data
        break;
      case "1Y":
        start.setTime(start.getTime() - 365 * 24 * 3600 * 1000);
        break;
      case "6m":
        start = new Date();
        start.setTime(start.getTime() - 0.5 * 365 * 24 * 3600 * 1000);
        break;
      case "3m":
        start = new Date();
        start.setTime(start.getTime() - 0.25 * 365 * 24 * 3600 * 1000);
        break;
      case "1m":
        start = new Date();
        start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
        break;
    }
    setTimeframe(start);
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <button
                className={styles.submitButton}
                type={"button"}
                onClick={() => setStartTime("1Y")}
              >
                1Y
              </button>
            </td>
            <td>
              <button
                className={styles.submitButton}
                type={"button"}
                onClick={() => setStartTime("6m")}
              >
                6m
              </button>
            </td>
            <td>
              <button
                className={styles.submitButton}
                type={"button"}
                onClick={() => setStartTime("3m")}
              >
                3m
              </button>
            </td>
            <td>
              <button
                className={styles.submitButton}
                type={"button"}
                onClick={() => setStartTime("1m")}
              >
                1m
              </button>
            </td>
            <td>
              <button
                className={styles.submitButton}
                type={"button"}
                onClick={() => setStartTime("All")}
              >
                All
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <MarketDepthChart 
        referenceLine={30}
        data={data}
        darkmode={darkmode}
      />
    </>
  );
}