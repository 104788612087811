import React from 'react'
import {Table} from 'react-bootstrap'
import {numberEditor, totalSummary} from '../../../Helpers/Helpers'
import {useAuth} from '../../../Hooks/useAuth'
import styles from "./Tables.module.scss"
import EmptyMessageComponent from "../../../Components/EmptyMessageComponent"
import {DashboardHoldingRiskComponent} from "../../../Components/Dashboard/DashboardHoldingRiskComponent";

const DashboardFundHoldings = ({holdings}) => {
  const {darkmode} = useAuth()
  if (holdings.length < 1) {
    return <EmptyMessageComponent message="No existing Holdings"/>
  }
  return (
    <>
      <h2 className={styles.tableTitle}>Holdings</h2>
      <Table responsive className="mt-4" borderless={true}>
        <thead>
        <tr className={styles.theader}>
          <th>Ticker</th>
          <th>Start Balance</th>
          <th>End Balance</th>
          <th>End USD Price</th>
          <th>USD Value</th>
          <th>At Risk (liq 20%)</th>
          <th>End BTC Price</th>
          <th>BTC Value</th>
          <th>End Percentage</th>
        </tr>
        </thead>
        {holdings && holdings.map((holding) => {
          return (
            <tbody key={holding.id}>
            <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}>
              <td>{holding.symbol} </td>
              <td>{numberEditor(holding.startBalance)} </td>
              <td>{numberEditor(holding.endBalance)} </td>
              <td>{numberEditor(holding.endUSDPrice)} </td>
              <td>{numberEditor(holding.endUSDValue)} </td>
              <td title={'2% liq: $' + numberEditor(holding.usdLiquidityTwoPercentDown)}>
                {DashboardHoldingRiskComponent(holding)}
              </td>
              <td>{numberEditor(holding.endBTCPrice)} </td>
              <td>{numberEditor(holding.endBTCValue)} </td>
              <td>{numberEditor(holding.endPercentage)} </td>
            </tr>
            </tbody>
          )
        })
        }
        <tfoot>
        <tr className={darkmode ? styles.tableStripsDark : styles.tableStrips}
            style={{fontWeight: "900", color: "#6D9E93"}}>
          <td></td>
          <th colSpan="3">Total :</th>
          <td>{totalSummary(holdings, "endUSDValue")}</td>
          <td></td>
          <td></td>
          <td>{totalSummary(holdings, "endBTCValue")}</td>
        </tr>
        </tfoot>
      </Table>
    </>
  )
}
export default DashboardFundHoldings