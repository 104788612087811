import {useQuery, useQueryClient, useMutation} from "react-query";
import {API_URL} from "../../settings";
import {simpleSuccessfulMessage} from "../../Helpers/Helpers"
import { DevaluationStepAddViewModel, VentureDealAddViewModel, VentureDealEditViewModel, VentureDealsApiFactory, VestingScheduleAddViewModel } from "@hodl.nl/hodltradingdesk"
import { useAuth } from "../../Hooks/useAuth";
import { SyntheticEvent } from "react";
import Swal from "sweetalert2";

// STATISTICS AVERAGES
// Function to get average prices by Base Asset and Order Direction
export const useVenturesDeals = (page: number) => {
  const { axiosInstance } = useAuth();
  const ventureDealsApi =  VentureDealsApiFactory(undefined, API_URL, axiosInstance)
  return useQuery(["ventureDeals", page], async () => ventureDealsApi.venturedealsGet('', '', page, 10).then(res => res.data))
}

export const useVentureDeal = (ventureDealId: string | undefined) => {
    const { axiosInstance } = useAuth();
    return useQuery(["ventureDeal", ventureDealId], async () => {
        if (ventureDealId) {
            return await VentureDealsApiFactory(undefined, API_URL, axiosInstance).venturedealsVentureDealIdGet(ventureDealId).then(res => res.data)
        }
    }, { enabled: !!ventureDealId })
}

// MUTATION
// Custom hook to post a Venture Deal
export const usePostVentureDeal = () => {
    const { axiosInstance } = useAuth();
    const ventureDeals = VentureDealsApiFactory(undefined, API_URL, axiosInstance);
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (data: VentureDealAddViewModel) => {
            try {
                const response = await ventureDeals.venturedealsPost(data)
                return response.data
            } catch (error) {
                console.log(error)
                throw new Error('An error occurred. Ask the console logs')
            }
        }, 
        onSuccess: () => {
            queryClient.invalidateQueries('ventureDeals')
        }
    });
};

// Custom hook to edit a Venture deal
export const useEditVentureDeal = () => {
    const { axiosInstance } = useAuth();
    const ventureDealsAPI = VentureDealsApiFactory(undefined, API_URL, axiosInstance);
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (params: { ventureDealId: string, data: VentureDealEditViewModel }) => {
            try {
                const response = await ventureDealsAPI.venturedealsVentureDealIdPut(params.ventureDealId, params.data)
                return response.data
            } catch (error) {
                console.log(error)
                throw new Error('An error occurred. Ask the console logs')
            }
        }, 
        onSuccess: () => {
            queryClient.invalidateQueries('ventureDeals')
            simpleSuccessfulMessage('Deal edited succesfully')
        }
    });

};

// Custom hook to delete note
export const useDeleteVentureDeal = () => {
    const { axiosInstance } = useAuth();
    const ventureDealsAPI = VentureDealsApiFactory(undefined, API_URL, axiosInstance);
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (ventureDealId: string) => {
            try {
                const response = await ventureDealsAPI.venturedealsVentureDealIdDelete(ventureDealId)
                return response.data
            } catch (error) {
                console.log(error)
                throw new Error('An error occurred. Ask the console logs')
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries('ventureDeals')
            simpleSuccessfulMessage('Deal deleted succesfully')
        }
    });
};
  
export const confirmationAlertDeleteVentureDeal = (event: SyntheticEvent<HTMLButtonElement>, ventureDealId: string) => {
    return new Promise<string>((resolve, reject) => {  
        event.preventDefault();
        
        Swal.fire({
            title: "Are you sure you want to delete this Venture Deal?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#6d9e93",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                return resolve(ventureDealId)
            } return reject
        });
  
    });
}

// Custom hook to post a Devaluation
export const usePostDevaluation = () => {
    const { axiosInstance } = useAuth();
    const ventureDeals = VentureDealsApiFactory(undefined, API_URL, axiosInstance);
    const queryClient = useQueryClient()
    return useMutation({ 
        mutationFn: async (params: { ventureDealId: string, data: DevaluationStepAddViewModel[] }) => {
            try {
                const response = await ventureDeals.venturedealsVentureDealIdDevaluationPost(params.ventureDealId, params.data)
                return response.data
            } catch (error) {
                console.log(error)
                throw new Error('An error occurred. Ask the console logs')
            }
        },     
        onSuccess: () => {
            queryClient.invalidateQueries('ventureDeals')
        }
    })
}

// Custom hook to post a Vesting
export const usePostVesting = () => {
    const { axiosInstance } = useAuth();
    const ventureDeals = VentureDealsApiFactory(undefined, API_URL, axiosInstance);
    const queryClient = useQueryClient()
    return useMutation({ 
        mutationFn: async (params: { ventureDealId: string, data: VestingScheduleAddViewModel[] }) => {
            try {
                const response = await ventureDeals.venturedealsVentureDealIdVestingPost(params.ventureDealId, params.data)
                return response.data
            } catch (error) {
                console.log(error)
                throw new Error('An error occurred. Ask the console logs')
            }
        },     
        onSuccess: () => {
            queryClient.invalidateQueries('ventureDeals')
        }
    })
}
 
// Custom hook to get Deal Devaluations
export const useDealDevaluations = (ventureDealId: string | undefined) => {
    const { axiosInstance } = useAuth();
    return useQuery(["devaluations", ventureDealId], async () => {
        if (ventureDealId) {
            return await VentureDealsApiFactory(undefined, API_URL, axiosInstance).venturedealsVentureDealIdDevaluationGet(ventureDealId).then(res => res.data)
        }
    }, { enabled: !!ventureDealId })
}
