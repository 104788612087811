import React from 'react';
import { BiChevronDown } from "react-icons/bi";
import { useFormContext } from "react-hook-form"
import styles from './Form.module.scss';
import { useAuth } from "../../../Hooks/useAuth";
import { Col } from 'react-bootstrap';

interface Props {
  label?: string,
  values?: { id: string, name: string }[],
  registerName: string,
  required: boolean,
  disabled?: boolean,
  value: string | number,
  name: string
}

const SelectGroupOne: React.FC<Props> = ({ label, values, registerName, required, disabled, value, name }) => {
  const { register, formState: { errors } } = useFormContext()
  const { darkmode } = useAuth()

  return (
    <>
      { label && 
          <label className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>
            { label }
            { required ? <span className={styles.reqSymbol}> *</span> : <span> Optional</span> }
          </label>
      }

      <div style={{position:'relative'}}>
        <select
          { ...register(registerName, { required }) }
          className={darkmode ? styles.selectInputDark : styles.selectInput}
          disabled={disabled}
          defaultValue=""
        >
          <option value="" disabled>
            { label }
          </option>
          { values && values.map((item: any, index: number) => (
              <option key={index} value={item[value]}>
                { item[name] }
              </option>
            ))  
          }
        </select>

        <span style={{ position:'absolute', right:'1rem', top:'50%', transform:'translateY(-50%)'}}>
          <BiChevronDown />
        </span>
      </div>
      { errors[registerName]?.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>  }
    </>
  );
};

export default SelectGroupOne;
