import styles from '../Ventures.module.scss'
import { Table } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useAuth } from '../../../Hooks/useAuth';
import { useEffect } from 'react';
import VestingFormRow from './VestingFormRow';
import { VestingScheduleListViewModel } from '@hodl.nl/hodltradingdesk';

interface Props {
  data?: VestingScheduleListViewModel[] | null | undefined,
  totalTokens: number,
  activationDate: string,
  control: any,
  setValue: any,
  reset: any
}

const VestingForm = ({ data, totalTokens, activationDate, control, setValue, reset }: Props) => {
  const { darkmode } = useAuth()
  const defaultTable = [ { period: 0, unlockDateTime: activationDate, unlockPercentage: 0, unlockAmount: 0 } ]
  const { fields, append, remove } = useFieldArray({ control, name: "vestingData" });

  const incrementMonth = (dateString: string, incremental: number) => {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + incremental);
    const incremementedDate = date.toISOString();
    return incremementedDate;
  }

  useEffect(() => {
    reset({ vestingData: data?.length ? data : defaultTable });
  }, [data?.length]);

  // Edit Mode: if data exists, set unlock dates values 
  useEffect(() => {
    if (data && data.length && fields.length) {
      data.forEach((item, index) => {
        const value = item?.unlockDateTime?.slice(0, 16) || "";
        setValue(`vestingData.${index}.unlockDateTime`, value);
      });
    }
  }, [data, fields.length, setValue]);

  return (
    <div className={styles.cardTableDark} style={{minHeight: '35rem', padding:'2rem'}}>
      <h2 className={styles.subTitle} style={{margin:"0 0 1.5rem 1rem"}}>Vesting Structure</h2>
      <Table>
          <thead>
              <tr className={darkmode ? styles.theaderDark : styles.theader}>
                  <th>Period</th>
                  <th>Unlocks %</th>
                  <th>Unlocks</th>
                  <th>Unlocks Date</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
            { fields.map((item, index) => 
                <VestingFormRow 
                  key={index} 
                  item={item} 
                  index={index} 
                  remove={remove} 
                  setValue={setValue}
                  totalTokens={totalTokens} 
                  activationDate={activationDate}
                  incrementMonth={incrementMonth}
                  data={data}
                  control={control}
                />
              )   
            }
          </tbody>
      </Table>
      <button type="button" onClick={() => append({ period: fields.length, unlockDateTime: incrementMonth(activationDate, fields.length).slice(0, 16), unlockPercentage: 0, unlockAmount: 0 }) } className={styles.editButton}>
        Add
      </button>
    </div>
  );
};
export default VestingForm;