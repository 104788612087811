import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import FundsLayerForm from './FundsLayerForm';
import { useCreateFundLayer, useEditFundLayer, useFundLayer, useFund } from "../../../query-hooks/fund-query-hooks/useFund"
import FormComponent from '../../../Components/FormComponent';
import { ErrorLoading, Spinner } from "../../../Helpers/Helpers"
import { useCheckRoles } from "../../../query-hooks/user-query-hooks/useUser"
import ForbiddenMessage from '../../../Components/ForbiddenMessage';

const FundsLayerMutation = () => {
    const { fundId, layerIndex } = useParams()
    const fund = useFund(fundId)
    const navigate = useNavigate();
    const createLayer = useCreateFundLayer()
    const editLayer = useEditFundLayer()
    const layer = useFundLayer(fundId, layerIndex)
    const onSubmit = data => layerIndex ? editLayer.mutate({ layer: data, fundId: fundId, layerIndex: layerIndex}) : createLayer.mutate({ layer: data, fundId: fundId})
    
    useEffect(() => { createLayer.isSuccess && navigate(-1) }, [createLayer])
    useEffect(() => { editLayer.isSuccess && navigate(-1) }, [editLayer])
        
    // Block to restrict view only to Admin User
    const isAdmin = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"])
    if (isAdmin.isSuccess && isAdmin.data === false) {
        return <ForbiddenMessage />
    }

    return (
        <FormComponent title={`${fund.isSuccess && fund.data.fundName} Fund`} subtitle={layerIndex ? "Edit Layer" : "Create Layer"}>
            { !layerIndex && <FundsLayerForm onSubmit={onSubmit} /> }
            { layer.isSuccess && <FundsLayerForm onSubmit={onSubmit} data={layer.data} /> }
            { layer.isLoading && <Spinner /> }
            { layer.isError && <ErrorLoading /> } 
        </FormComponent>
    );
};
export default FundsLayerMutation
