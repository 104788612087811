import React, { useEffect, useState } from 'react'
import styles from "../FundDetail.module.scss"
import { Row, Col } from 'react-bootstrap'
import { useAuth } from '../../../Hooks/useAuth'
import {bookingPerioddateFormat, numberEditor} from '../../../Helpers/Helpers'
import {useFundClassesByBookingPeriod} from '../../../query-hooks/fund-query-hooks/useFund'
import MergeClassesModal from './MergeClassesModal'

const ClassDashboardComponent = ({ fundId, bookingPeriod, setSelectedFundClassId }) => {
    const { darkmode } = useAuth()
    const [ selectedFundClass, setSelectedFundClass ] = useState()
    const [selectedClassId, setSelectedClassId] = useState(""); // State ONLY to control select input value
    const fundClassesByBookingPeriod = useFundClassesByBookingPeriod(fundId, bookingPeriod)

    const handleClassSelection = fundClassId => {
        setSelectedFundClass(fundClassesByBookingPeriod?.data.find(fundClass => fundClass.id === fundClassId))
        setSelectedFundClassId(fundClassId)
    }

    // If a different Booking Period is selected in the Dashboard, refresh the selected class ID to undefined to display Main Fund Data for selected Booking period
    useEffect(() => { 
        setSelectedFundClassId()  // reset selected class ID when a different booking period is selected
        setSelectedFundClass() // reset fund class data when a different booking period is selected
        setSelectedClassId(""); // reset select input when a different booking period is selected
    }, [ bookingPeriod, fundClassesByBookingPeriod.data ])

    if (fundClassesByBookingPeriod.isSuccess && fundClassesByBookingPeriod.data?.length > 0) {
        return (
            <div className={darkmode ? styles.classMetadataCardDark : styles.classMetadataCard}>
                <Col xl={12}>
                    <Row>
                        <h5 className={styles.secondaryFundTitle}>Classes</h5>
                        <Col xl={3}>
                            <select 
                                className={darkmode ? styles.selectInputDark : styles.selectInput} 
                                style={{marginBottom:"0"}}
                                value={selectedClassId}
                                onChange={e => {
                                    setSelectedClassId(e.target.value); 
                                    handleClassSelection(e.target.value);
                                }}
                            >
                                <option value="">{ selectedFundClass ? "Select Main Fund" : "Select a Class"}</option>
                                { fundClassesByBookingPeriod.data.map(fundClass => (<option key={fundClass.id} value={fundClass.id}>{ fundClass.className }</option>)) }
                            </select>
                            { selectedFundClass &&  <MergeClassesModal fundId={fundId} selectedFundClass={selectedFundClass} classes={fundClassesByBookingPeriod.data} />}
                        </Col>
                        <Col xl={9}>
                            {
                                selectedFundClass &&
                                <Row>
                                    <Col xl={3}>
                                        <h5 className={styles.headerData}>{ selectedFundClass.nav ? numberEditor(selectedFundClass.nav.nav) : '-' }</h5>
                                        <h5 className={styles.headerTitle}>NAV Period {selectedFundClass.nav ? bookingPerioddateFormat( selectedFundClass.nav.bookingPeriod) : ''}</h5>
                                    </Col>
                                    <Col xl={3}>
                                        <h5 className={styles.headerData}>{ selectedFundClass.nav ? numberEditor(selectedFundClass.nav.hwm) : '-' }</h5>
                                        {
                                            // left this here as a comparison. The SharesValueHWM should be the same as
                                            // the value on the nav - for the current booking period - we now show the
                                            // selected booking period instead of the latest booking period
                                            //<h5 className={styles.headerData}>{ numberEditor(selectedFundClass.shareValueHWM) }</h5>
                                        }
                                        <h5 className={styles.headerTitle}>HWM</h5>
                                    </Col>
                                    <Col xl={3}>
                                        <h5 className={styles.headerData}>{ selectedFundClass.nav ? numberEditor(selectedFundClass.nav.totalShares) : '-' }</h5>
                                        <h5 className={styles.headerTitle}>Total Shares</h5>    
                                    </Col>
                                    <Col xl={3}>
                                        <h5 className={styles.headerData}>{ selectedFundClass.nav ? numberEditor(selectedFundClass.nav.totalValue) : '-' }</h5>
                                        <h5 className={styles.headerTitle}>Total Value</h5>    
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    }
}
export default ClassDashboardComponent;