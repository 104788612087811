import React, { useState } from 'react'
import { useCheckRole } from '../../query-hooks/user-query-hooks/useUser' 
import styles from "./AllUsers.module.scss"

/** ignored: rolesData */
const RolesInput = ({ _, userId, confirmationAlert }) => {
    const [ selected, setSelected ] = useState()
    const isAdmin = useCheckRole("ADMIN")
    const isLeadTrader = useCheckRole("LEADTRADER")
    const isLeadVentures = useCheckRole("LEADVENTURES")
    const isHeadSales = useCheckRole("HEADSALES")

    const onHandleConfirmationAlert = (even, action, userId, value, text) => {
        confirmationAlert(even, action, userId, value, text)
        setSelected(1) // Trigger to reset select input 
    }

    const leadTraderAllowed = (isAdmin.isSuccess && isAdmin.data) || (isLeadTrader.isSuccess && isLeadTrader.data);
    const leadVenturesAllowed = (isAdmin.isSuccess && isAdmin.data) || (isLeadVentures.isSuccess && isLeadVentures.data);
    const headSalesAllowed = (isAdmin.isSuccess && isAdmin.data) || (isHeadSales.isSuccess && isHeadSales.data);

    const roles = [
        { name: "Lead Trader", value: "LEADTRADER", include: leadTraderAllowed },
        { name: "Trader", value: "TRADER", include: leadTraderAllowed },
        { name: "Lead Ventures", value: "LEADVENTURES", include: leadVenturesAllowed }, 
        { name: "Ventures", value: "VENTURES", include: leadVenturesAllowed },
        { name: "Head Sales", value: "HEADSALES", include: headSalesAllowed },
        { name: "Sales", value: "SALES", include: headSalesAllowed },
    ]

    return (
            <select className={styles.selectInput} onChange={e => onHandleConfirmationAlert(e, 'put', userId, e.target.value, "Yes, assign the role")} defaultValue="1" value={selected}>
                <option className={styles.options} value={1} disabled={true}>Select a role</option>
                {
                    roles.filter(role => role.include).map(role => <option key={role.value} value={role.value} className={styles.options}>{ role.name }</option>)
                }
            </select>
    )
}
export default RolesInput;