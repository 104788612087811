import NumberInput from '../../../Components/FormInputs/Typescript/NumberInput';
import { MdDelete } from "react-icons/md";
import ControlledDateTime from '../../../Components/FormInputs/Typescript/Controlled/ControlledDateTime';
import { useAuth } from '../../../Hooks/useAuth';
import styles from '../Ventures.module.scss'
import { useEffect } from 'react';
import { Control, UseFieldArrayRemove, UseFormSetValue, useWatch } from 'react-hook-form';
import { VestingScheduleListViewModel } from '@hodl.nl/hodltradingdesk';

interface Props {
    item: any,
    index: number,
    remove: UseFieldArrayRemove;
    totalTokens: number,
    setValue: UseFormSetValue<{
        vestingData: {
            period: number;
            unlockDateTime: string;
            unlockPercentage: number;
            unlockAmount: number;
        }[]}
    >,
    activationDate: string,
    incrementMonth: (dateString: string, incremental: number) => string,
    data: VestingScheduleListViewModel[] | null | undefined,
    control: Control
}

const VestingFormRow = ({ item, index, remove, totalTokens, setValue, activationDate,  incrementMonth, data, control }: Props) => {
  const { darkmode } = useAuth()
  const unlockPercentage = useWatch({ name: `vestingData.${index}.unlockPercentage` });

  useEffect(() => {
    if (unlockPercentage && totalTokens) {
      setValue(`vestingData.${index}.unlockAmount`, (unlockPercentage * totalTokens) / 100)
    }
  }, [ unlockPercentage, totalTokens ])
  
  // Force update when activationDate changes
  useEffect(() => {
      if (index === 0 && activationDate) {
        const newDate = new Date(activationDate).toISOString().slice(0, 16);
        setValue(`vestingData.${index}.unlockDateTime`, newDate);
      } else if (index > 0 && activationDate) {
        const newDate = incrementMonth(activationDate, index).slice(0, 16);
        setValue(`vestingData.${index}.unlockDateTime`, newDate);
      }
  }, [ activationDate, index, setValue ]);

  // Edit Mode: if data exists, set unlock dates values
  useEffect(() => {
    if (data && data.length && data[index]?.unlockDateTime) {
      const newDate = data[index]?.unlockDateTime.slice(0, 16);
      setValue(`vestingData.${index}.unlockDateTime`, newDate);
    }
  }, [ data ]);

  return (
    <tr key={item.id} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
      <td><NumberInput registerName={`vestingData.${index}.period`} required={true} defaultValue={item.period} /></td>
      <td><NumberInput registerName={`vestingData.${index}.unlockPercentage`} required={false} defaultValue={item.unlockPercentage} /></td>
      <td><NumberInput registerName={`vestingData.${index}.unlockAmount`} required={false} defaultValue={item.unlockAmount} /></td>
      <td><ControlledDateTime registerName={`vestingData.${index}.unlockDateTime`} required={true} defaultDate={activationDate} control={control} /></td>
      <td>
        <button type="button" className="bg-transparent border-0" onClick={() => remove(index)}>
            <MdDelete style={{color:'#6D9E93', fontSize:'1.1rem'}} />
        </button>
      </td>
    </tr>
  )
}
export default VestingFormRow;