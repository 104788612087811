import React from 'react'
import Login from "./registration/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./registration/ProtectedRoute";
import Dashboard from "./dashboard/Dashboard/Dashboard";
import { AuthProvider } from "./Hooks/useAuth";
import { QueryClientProvider, QueryClient } from "react-query";
import Register from "./registration/Register";
import Authentication from "./registration/Authentication";
import ResetPassword from "./registration/ResetPassword";
import CryptosDashboard from "./dashboard/Cryptos/CryptosDashboard";
import NavigationBar from "./dashboard/NavigationBar/NavigationBar";
import CryptoFormMutation from "./dashboard/Cryptos/CryptoFormMutation";
import ListingFormMutation from "./dashboard/Cryptos/CryptoListings/ListingFormMutation";
import CurrenciesDashboard from './dashboard/Currencies/CurrenciesDashboard';
import ScrollToTopHelper from './Helpers/ScrollToTopHelper';
import CurrencyFormMutation from './dashboard/Currencies/CurrencyFormMutation';
import FundsDashboard from './dashboard/Funds/FundsDashboard';
import FundFormMutation from './dashboard/Funds/FundFormMutation';
import FundDetail from './dashboard/Funds/FundDetail';
import FundsOwnerFormCreate from './dashboard/Funds/FundsOwners/FundsOwnerFormCreate';
import FundsLayerMutation from './dashboard/Funds/FundsLayers/FundsLayerMutation';
import CategoryFormMutation from './dashboard/Categories/CategoryFormMutation';
import FundsHoldingCreate from './dashboard/Funds/FundsHoldings/FundsHoldingCreate';
import FundsHoldingEdit from './dashboard/Funds/FundsHoldings/FundsHoldingEdit';
import BanksDashboard from './dashboard/Banks/BanksDashboard';
import BankFormMutation from './dashboard/Banks/BankFormMutation';
import FundBankAccountMutation from './dashboard/Funds/FundsBankAccounts/FundBankAccountMutation';
import FundExchangeAccountMutation from './dashboard/Funds/FundsExchangeAccounts/FundExchangeAccountMutation';
import MainUsersDashboard from './dashboard/Users/MainUsersDashboard';
import ExchangesDashboard from './dashboard/Exchanges/ExchangesDashboard';
import ExchangeFormMutation from './dashboard/Exchanges/ExchangeFormMutation';
import ExchangeAccountFormMutation from './dashboard/Exchanges/ExchangeAccounts/ExchangeAccountFormMutation';
import WalletsDashboard from './dashboard/Wallets/WalletsDashboard';
import WalletFormMutation from './dashboard/Wallets/WalletFormMutation';
import WalletBalancesDashboard from './dashboard/Wallets/WalletBalances/WalletBalancesDashboard';
import WalletBalanceFormMutation from './dashboard/Wallets/WalletBalances/WalletBalanceFormMutation';
import TransferFormMutation from './dashboard/Transfers/TransferFormMutation';
import OrdersDashboard from './dashboard/Orders/OrdersDashboard';
import OrderFormMutation from './dashboard/Orders/OrderFormMutation';
import AllTrades from './dashboard/Orders/Trades/AllTrades';
import BankAccountBalanceFormMutation from './dashboard/Banks/BankAccountBalances/BankAccountBalanceFormMutation';
import VersionChecker from './VersionChecker';
import CategoriesDashboard from './dashboard/Categories/CategoriesDashboard';
import TradeFormMutation from './dashboard/Orders/Trades/TradeFormMutation';
import CurrencyRateFormMutation from './dashboard/Currencies/Rates/CurrencyRateFormMutation';
import DashboardFundDetail from './dashboard/Dashboard/DashboardFundDetail';
import Reports from './dashboard/Reports/Reports';
import OrderImports from './dashboard/Orders/OrderImport/OrderImports';
import BankAccountFormMutation from './dashboard/Banks/BankAccounts/BankAccountFormMutation';
import Settings from './dashboard/Users/Settings/Settings';
import MFAuthChecker from './dashboard/Users/Settings/MFAuthChecker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './settings';
import RecoveryMFA from './dashboard/Users/Settings/RecoveryMFA';
import Sandbox from './dashboard/Dashboard/Sandbox/Sandbox';
import Averages from './dashboard/Dashboard/Averages/Averages';
import PnLDashboard from './dashboard/Dashboard/PnL/PnLDashboard';
import OrderBlockchainImport from './dashboard/Orders/OrderImport/BlockchainImport/OrderBlockchainImport';
import BenchmarkDashboard from './dashboard/Benchmark/BenchmarkDashboard';
import FundClassFormMutation from './dashboard/Funds/Classes/FundClassFormMutation';
import NotFound from './NotFound';
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComponent from './ErrorBoundaryComponent';
import MarketDepthPage from "./dashboard/MarketDepth/MarketDepthPage";
import {BlockchainNetworksDashboard} from "./dashboard/BlockchainNetworks/BlockchainNetworksDashboard";
import {BlockchainNetworksDetails} from "./dashboard/BlockchainNetworks/BlockchainNetworksDetails";
import VenturesDashboard from './dashboard/Ventures/VenturesDashboard';
import VenturesDetail from './dashboard/Ventures/VenturesDetail';
import DealMutationForm from './dashboard/Ventures/Deals/DealMutationForm';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // React query function to avoid refetching when reopening browser's window
      },
    },
  })

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ScrollToTopHelper>
            <VersionChecker>
            <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
              {/* <SignalRProvider> */}
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <Routes>
                    {/* Registration System */}
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/reset-password" element={<ResetPassword />} />
                    <Route exact path="/authentication" element={<Authentication />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/mfa-check" element={ <MFAuthChecker /> } />
                    <Route exact path="/recovery-mfa" element={ <RecoveryMFA /> } />
                    {/* Main dashboard routes nested */}
                    <Route path="main" element={ <ProtectedRoute> <NavigationBar /> </ProtectedRoute> } >
                      <Route index element={<Dashboard />} />
                      <Route path="/main/dashboard" element={<Dashboard />} />
                      <Route exact path="/main/dashboard/:fundId" element={ <DashboardFundDetail /> } />
                      <Route exact path="/main/dashboard/sandbox/:fundId" element={ <Sandbox /> } />
                      <Route exact path="/main/average-prices" element={ <Averages /> } />
                      <Route exact path="/main/pnl" element={ <PnLDashboard /> } />
                      <Route exact path="/main/benchmark" element={ <BenchmarkDashboard /> } />
                      <Route exact path="/main/preferences" element={<Settings />} />
                      <Route exact path="/main/reports" element={ <Reports /> } />
                      <Route exact path="/main/settings" element={ <MainUsersDashboard /> } />
                      <Route exact path="/main/cryptos" element={<CryptosDashboard />} />
                      <Route exact path="/main/cryptos/create-crypto" element={<CryptoFormMutation />} />
                      <Route exact path="/main/cryptos/edit-crypto/:crypto_id" element={<CryptoFormMutation />} />
                      <Route exact path="/main/cryptos/create-listing/:crypto_id" element={<ListingFormMutation />} />
                      <Route exact path="/main/cryptos/:crypto_id/edit-listing/:listingId" element={<ListingFormMutation />} />
                      <Route exact path="/main/marketdepth" element={ <MarketDepthPage /> } />
                      <Route exact path="/main/currencies" element={<CurrenciesDashboard />} />
                      <Route exact path="/main/currencies/create-currency" element={ <CurrencyFormMutation /> } />
                      <Route exact path="/main/currencies/edit-currency/:isoCode" element={ <CurrencyFormMutation /> } />
                      <Route exact path="/main/currencies/create-currency-rate/:isoCode" element={ <CurrencyRateFormMutation />} />
                      <Route exact path="/main/funds" element={ <FundsDashboard /> } />
                      <Route exact path="/main/funds/create-fund" element={ <FundFormMutation /> } />
                      <Route exact path="/main/funds/edit-fund/:fundId" element={ <FundFormMutation /> } />
                      <Route exact path="/main/funds/fund-detail/:fundId" element={ <FundDetail /> } />
                      <Route exact path="/main/funds/create-fund-owner" element={ <FundsOwnerFormCreate /> } />
                      <Route exact path="/main/funds/create-fund-layer/:fundId" element={ <FundsLayerMutation /> } />
                      <Route exact path="/main/funds/edit-fund-layer/:fundId/:layerIndex" element={ <FundsLayerMutation /> } />
                      <Route exact path="/main/categories" element={ <CategoriesDashboard /> } />
                      <Route exact path="/main/categories/create-category" element={ <CategoryFormMutation /> } />
                      <Route exact path="/main/categories/edit-category/:categoryId" element={ <CategoryFormMutation /> } />
                      <Route exact path="/main/funds/create-fund-holding/:fundId" element={ <FundsHoldingCreate /> } />
                      <Route exact path="/main/funds/edit-holding/:fundId/:holdingId" element={ <FundsHoldingEdit /> } />
                      <Route exact path="/main/funds/create-fund-holding-transfer/:fundId/" element={ <TransferFormMutation /> } />
                      <Route exact path="/main/funds/edit-fund-holding-transfer/:fundId/:transferId" element={ <TransferFormMutation /> } />
                      <Route exact path="/main/funds/:fundId/create-fund-class" element={ <FundClassFormMutation /> } />
                      <Route exact path="/main/funds/:fundId/:fundClassId/edit-fund-class" element={ <FundClassFormMutation /> } />
                      <Route exact path="/main/banks" element={ <BanksDashboard /> } />
                      <Route exact path="/main/banks/create-bank" element={ <BankFormMutation /> } />
                      <Route exact path="/main/banks/edit-bank/:bankId" element={ <BankFormMutation /> } />
                      <Route exact path="/main/banks/create-bank-account/:bankId" element={ <BankAccountFormMutation /> } />
                      <Route exact path="/main/banks/edit-bank-account/:bankId/:bankAccountId" element={ <BankAccountFormMutation /> } />
                      <Route exact path="/main/funds/create-fund-bank-account/:fundId" element={ <FundBankAccountMutation/> } />
                      <Route exact path="/main/funds/edit-fund-bank-account/:fundId/:bankAccountId" element={ <FundBankAccountMutation/> } />
                      <Route exact path="/main/funds/bank-account/balances/create-balance/:bankAccountId" element={ <BankAccountBalanceFormMutation /> } />
                      <Route exact path="/main/funds/bank-account/balances/edit-balance/:bankAccountId/:bankBalanceId" element={ <BankAccountBalanceFormMutation /> } />
                      <Route exact path="/main/funds/create-fund-exchange-account/:fundId" element={ <FundExchangeAccountMutation/> } />
                      <Route exact path="/main/funds/edit-fund-exchange-account/:fundId/:exchangeAccountId" element={ <FundExchangeAccountMutation/> } />
                      <Route exact path="/main/networks" element={ <BlockchainNetworksDashboard /> } />
                      <Route exact path="/main/networks/edit-network/:blockchainNetworkId" element={ <BlockchainNetworksDetails /> } />
                      <Route exact path="/main/networks/create-network" element={ <BlockchainNetworksDetails /> } />
                      <Route exact path="/main/exchanges" element={ <ExchangesDashboard /> } />
                      <Route exact path="/main/exchanges/create-exchange" element={ <ExchangeFormMutation /> } />
                      <Route exact path="/main/exchanges/edit-exchange/:exchangeId" element={ <ExchangeFormMutation /> } />
                      <Route exact path="/main/exchanges/create-exchange-account/:exchangeId" element={ <ExchangeAccountFormMutation /> } />
                      <Route exact path="/main/exchanges/edit-exchange-account/:exchangeAccountId/:exchangeId" element={ <ExchangeAccountFormMutation /> } />
                      <Route exact path="/main/wallets" element={ <WalletsDashboard /> } />
                      <Route exact path="/main/wallets/create-wallet" element={ <WalletFormMutation /> } />
                      <Route exact path="/main/wallets/edit-wallet/:address" element={ <WalletFormMutation /> } />
                      <Route exact path="/main/wallets/balances/:address" element={ <WalletBalancesDashboard /> } />
                      <Route exact path="/main/wallets/balances/create-balance/:address" element={ <WalletBalanceFormMutation /> } />
                      <Route exact path="/main/wallets/balances/edit-balance/:address/:balanceId" element={ <WalletBalanceFormMutation /> } />
                      <Route exact path="/main/orders" element={ <OrdersDashboard /> } />
                      <Route exact path="/main/orders/create-order" element={ <OrderFormMutation /> } />
                      <Route exact path="/main/orders/edit-order/:orderId" element={ <OrderFormMutation /> } />
                      <Route exact path="/main/orders/trades/:orderId" element={ <AllTrades /> } />
                      <Route exact path="/main/orders/trades/create-trade/:orderId" element={ <TradeFormMutation /> } />
                      <Route exact path="/main/orders/import-orders" element={ <OrderImports /> } />
                      <Route exact path="/main/orders/blockchain-import-orders" element={ <OrderBlockchainImport /> } />
                      <Route exact path="/main/orders/blockchain-import-orders/:orderId/:walletAddressParams" element={ <OrderBlockchainImport /> } />
                      <Route exact path="/main/ventures" element={ <VenturesDashboard /> } />
                      <Route exact path="/main/ventures/create-deal" element={ <DealMutationForm /> } />
                      <Route exact path="/main/ventures/edit-deal/:ventureDealId" element={ <DealMutationForm /> } />
                      <Route exact path="/main/ventures/details/:ventureDealId" element={ <VenturesDetail /> } />
                      <Route path='*' element={<NotFound />}/>
                    </Route>
                  </Routes>
                </GoogleOAuthProvider>
              {/* </SignalRProvider> */}
              </ ErrorBoundary>
            </VersionChecker>
          </ScrollToTopHelper>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
