import { VentureDealAddViewModel, VentureDealDetailViewModel } from '@hodl.nl/hodltradingdesk';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import TextInput from '../../../Components/FormInputs/Typescript/TextInput';
import NumberInput from '../../../Components/FormInputs/Typescript/NumberInput';
import DateTime from '../../../Components/FormInputs/Typescript/DateTime';
import { Col, Row } from 'react-bootstrap';
import styles from '../Ventures.module.scss';
import SelectInput from '../../../Components/FormInputs/Typescript/SelectInput';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../Hooks/useAuth';
import DevaluationForm from '../Devaluations/DevaluationForm';
import VestingForm from '../Vestings/VestingForm';
import { Link } from 'react-router-dom';

interface Props {
    data?: VentureDealDetailViewModel,
    onSubmit: SubmitHandler<VentureDealAddViewModel>
}

const DealForm = ({ data, onSubmit }: Props) => {
    const { darkmode } = useAuth()
    const methods = useForm<VentureDealAddViewModel>()
    const { reset, watch, control, setValue } = methods
    const [ totalTokens, setTotalTokens ] = useState<number>(0)

    // Set activation date to the 1st day of the month
    const firstDayOfCurrentMonth = new Date();
    firstDayOfCurrentMonth.setDate(1); 
    firstDayOfCurrentMonth.setHours(13, 0, 0, 0);
    const [ activationDate, setActivationDate ] = useState<string>(firstDayOfCurrentMonth.toISOString().slice(0, 16))

    // Watch for changes in totalTokens
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'totalTokens' && type === 'change' && value.totalTokens) {
                setTotalTokens(value.totalTokens)
            }
            if (name === 'activationDate' && value.activationDate) {
                setActivationDate(value.activationDate)
            }
            if (name === 'tgeDate' && value.tgeDate) {
                setActivationDate(value.tgeDate)
                setValue('activationDate', value.tgeDate)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    // Set default values for edit mode
    useEffect(() => {
        if (data) {
            reset({
                activationDate: data.activationDate?.slice(0, 16),
                contractAddress: data.contractAddress,
                contractDate: data.contractDate?.slice(0, 16),
                dealName: data.dealName,
                endDate: data.endDate?.slice(0, 16),
                tgeDate: data.tgeDate?.slice(0, 16),
                totalTokens: data.totalTokens,
                valuationType: data.valuationType
            })
            data.totalTokens && setTotalTokens(data.totalTokens)
        }
    }, [data, reset])

    // Set default values for Valuation Type on Create Mode
    useEffect(() => { !data && setValue('valuationType', 'DevaluationLadder') }, [data, setValue])

    return (
        <>
            {/* <!-- Create / Edit Venture Deal --> */}
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row className="d-flex justify-content-end mt-2">
                        <Col md={10} className='d-flex justify-content-start'>
                            <h2 className={darkmode ? styles.subTitleDark : styles.subTitle} style={{fontSize:'35px', fontWeight:'500'}}>{ data ? 'Edit Deal' : 'Create Deal' }</h2>
                        </Col>
                        <Col md={2} className='d-flex justify-content-end gap-3'>
                            <Link to='/main/ventures' type="button" className={styles.createButton} style={{margin:'1rem 0', background:'#CD5C5C'}}>Cancel</Link>
                            <input className={styles.submitButton} style={{fontSize:'.9rem'}} type="submit" value="Save" />
                        </Col>
                    </Row>
                    <div className={darkmode ? styles.cardTableDark : styles.cardTable} style={{padding:'2rem'}}>
                        <Row className='my-2'>
                            <Col xl={3} className={styles.metadata}><h4>Deal Name</h4><h5><TextInput label="" registerName="dealName" required={true} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>Contract Address</h4><h5><TextInput label="" registerName="contractAddress" required={false} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>Total Tokens</h4><h5><NumberInput label="" registerName="totalTokens" required={true} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>Valuation Type</h4><h5><SelectInput label="" values={[{id:'DevaluationLadder', name:'Devaluation Ladder'}, {id:'DynamicDevaluation', name:'Dynamic Devaluation'}]} registerName="valuationType" required={true} value="id" name="name" /></h5></Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xl={3} className={styles.metadata}><h4>Contract Date</h4><h5><DateTime registerName='contractDate' required={true} defaultDate={true} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>TGE Date</h4><h5><DateTime registerName='tgeDate' required={true} defaultDate={activationDate} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>Activation Date</h4><h5><DateTime registerName='activationDate' required={true} defaultDate={activationDate} /></h5></Col>
                            <Col xl={3} className={styles.metadata}><h4>End Date</h4><h5><DateTime registerName='endDate' required={true} defaultDate={true} /></h5></Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md={6}>
                            <DevaluationForm data={data?.devaluationSteps} />
                        </Col>
                        <Col md={6}>
                            <VestingForm 
                                data={data?.vestingSchedules}
                                totalTokens={totalTokens}
                                activationDate={activationDate}
                                control={control}
                                setValue={setValue}
                                reset={reset}
                            />
                        </Col>
                    </Row>
                </form>
            </FormProvider>
        </>
    )
}
export default DealForm;