import axios from "axios";
import {useMutation, useQuery} from "react-query";
import { API_URL } from "../../settings";
import { CryptoCurrencyApi, LiquidatableFundBalance } from "@hodl.nl/hodltradingdesk";
import { useAuth } from "../../Hooks/useAuth";

/**
 * Fetches current market depth data for a given crypto
 * @param {string} cryptoGuid
 * @returns {Promise<MarketDepthData[]>}
 */
const fetchMarketDepth = (cryptoGuid: string) =>
  axios
    .get(`${API_URL}/cryptos/depth/current/${cryptoGuid}`)
    .then((response) => response.data);

/**
 * Fetch current market depth for given crypto from cache or fetch from net.
 * @param cryptoGuid
 * @returns {import('react-query').UseQueryResult<MarketDepthData[], unknown>}
 */
export function useCurrentMarketDepth(cryptoGuid: string) {
  return useQuery(
    ["marketDepth", "current", cryptoGuid],
    () => fetchMarketDepth(cryptoGuid),
    { enabled: cryptoGuid !== undefined },
  );
}

/**
 * Fetches market depth history for a given crypto
 * @param {string} cryptoGuid uuid for crypto
 * @param {Date} startDate start fetching time from this date
 * @returns {Promise<MarketDepthHistoryList>}
 */
const fetchMarketDepthHistory = (cryptoGuid: string, startDate?: Date) =>
    axios
      .get(`${API_URL}/cryptos/depth/history/${cryptoGuid}`, {params: {start: startDate}})
      .then((response)=>response.data);

/**
 * @param {string} cryptoGuid uuid for crypto
 * @param {Date} startDate start fetching time from this point
 * @returns {import('react-query').UseQueryResult<MarketDepthHistoryList, unknown>}
 */
export function useMarketDepthHistory(cryptoGuid: string, startDate?: Date) {
  return useQuery(
      ["marketDepthHistory", cryptoGuid, startDate],
      ()=>fetchMarketDepthHistory(cryptoGuid, startDate),
      {enabled: cryptoGuid != null});
}

const postUpdateMarketDepthRequest = async () => await axios.post(`${API_URL}/cryptos/depth/runmanually`).then(response => response.data);
export function useRunMarketDepthManually() {
  return useMutation(()=> postUpdateMarketDepthRequest());
}

export function useFundDepthHistory(fundGuid: string, startDate?: Date) {
  const {axiosInstance} = useAuth();

  return useQuery<LiquidatableFundBalance[]>(
    ["fundDepthHistory", fundGuid, startDate],
    async () => {
      const api = new CryptoCurrencyApi(undefined, API_URL, axiosInstance);
      const response = await api.liquidityhistoryFundIdGet(
        fundGuid,
        startDate?.toISOString(),
        new Date().toISOString(),
        undefined
      );
      return response.data;
    },
    {
      enabled: fundGuid != null && fundGuid !== "null",
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
}