/**
 * A warning icon component that displays a popup message when clicked.
 * Shows yellow for overridden values and red for missing coin data.
 * Includes a form for submitting average prices when needed.
 */
import React, { useState, useRef, useEffect } from 'react';
import { Overlay, Form, Button } from 'react-bootstrap';

interface WarningPopupProps {
  hasOverriddenStartingBalance: boolean;
  missingCoinAmount: number;
  baseAssetSymbol: string;
  overriddenPrice: number;
  totalAdjustedAmount: number;
  quoteAssetSymbol: string;
  quoteOrderAmount: number;
  onSubmitPrice: (price: number) => void;
  onRemovePrice: () => void;
  isSubmitting: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export function WarningPopup({ 
  hasOverriddenStartingBalance, 
  missingCoinAmount,
  overriddenPrice,
  baseAssetSymbol,
  totalAdjustedAmount,
  quoteAssetSymbol,
  quoteOrderAmount,
  onSubmitPrice,
  onRemovePrice,
  isSubmitting,
  isError,
  isSuccess
}: WarningPopupProps) {
  const [show, setShow] = useState(false);
  const [averagePrice, setAveragePrice] = useState<string>("");
  const target = useRef(null);

  useEffect(()=>{
    if(overriddenPrice){
      setAveragePrice(overriddenPrice.toString())
    }
  }, [])

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (target.current && !(target.current as any).contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (averagePrice && !isNaN(parseFloat(averagePrice))) {
      onSubmitPrice(parseFloat(averagePrice));
    }
  };

  const warningColor = hasOverriddenStartingBalance ? '#FFD700' : '#FF6B6B';

  return (
    <React.Fragment>
      <span
        ref={target}
        onClick={() => setShow(!show)}
        style={{
          cursor: 'pointer',
          color: warningColor,
          marginLeft: '5px'
        }}
      >
        {Math.abs(missingCoinAmount) < 0.01 ? '⚠️': '❗️'}
      </span>
      {Math.abs(quoteOrderAmount) > 0.01 ? <span title={`Bought other with this currency: ${quoteOrderAmount.toFixed(6)} ${baseAssetSymbol} worth bought.`}>ℹ</span> : null}

      <Overlay
        target={target.current}
        show={show}
        placement="right"
      >
        {({
          placement,
          arrowProps,
          show: _show,
          popper,
          ...props
        }) => (
          <div
            {...props}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            style={{
              backgroundColor: 'white',
              padding: '15px',
              color: '#000',
              borderRadius: '3px',
              boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
              border: `2px solid ${warningColor}`,
              position: 'absolute',
              zIndex: 1000,
              marginLeft: '10px',
              maxWidth: '350px',
              ...props.style,
            }}
          >
            <strong>
              {hasOverriddenStartingBalance ? 'Starting Balance Set' : 'Missing Coin Data Warning'}
            </strong>
            <div style={{ marginTop: '5px', marginBottom: '15px' }}>
              {hasOverriddenStartingBalance ? (
                <>
                  A custom starting price has been set for this asset.
                  <br />
                  Total adjusted amount: {totalAdjustedAmount.toFixed(6)} {baseAssetSymbol}
                </>
              ) : (
                <>
                  We've detected {missingCoinAmount.toFixed(6)} {baseAssetSymbol} more coins being sold than being bought.
                  This might indicate missing data from before the trading desk was taken in use, or received staking rewards/payouts.
                </>
              )}
            </div>

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Average unit price for missing coins</Form.Label>
                <div className="d-flex gap-2">
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="number"
                      step="any"
                      value={averagePrice}
                      onChange={(e) => setAveragePrice(e.target.value)}
                      placeholder={`Enter price in ${quoteAssetSymbol}`}
                      style={{ width: '200px' }}
                    />
                    {!!overriddenPrice && (
                      <span 
                        onClick={() => {
                          onRemovePrice();
                          setAveragePrice("");
                          setShow(false);
                        }}
                        style={{
                          cursor: 'pointer',
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: '#FF6B6B'
                        }}
                      >
                        ✕
                      </span>
                    )}
                  </div>
                  <Button
                    type="submit"
                    variant="dark"
                    disabled={!averagePrice || isNaN(parseFloat(averagePrice)) || isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
                {isError && (
                  <div className="text-danger mt-2">
                    Failed to submit price. Please try again.
                  </div>
                )}
                {isSuccess && (
                  <div className="text-success mt-2">
                    Price submitted successfully!
                  </div>
                )}
              </Form.Group>
            </Form>
          </div>
        )}
      </Overlay>
    </React.Fragment>
  );
}
