import styles from '../Ventures.module.scss'
import { useForm, useFieldArray } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { DevaluationStepListViewModel } from '@hodl.nl/hodltradingdesk';
import NumberInput from '../../../Components/FormInputs/Typescript/NumberInput';
import { Table } from 'react-bootstrap';
import { useAuth } from '../../../Hooks/useAuth';
import { useEffect } from 'react';

interface Props {
  data: DevaluationStepListViewModel[] | null | undefined,
}

const DevaluationForm = ({ data }: Props) => {
  const defaultTable = [
    { periodDistance: 1, devaluation: 5 },
    { periodDistance: 3, devaluation: 10 },
    { periodDistance: 6, devaluation: 15 },
    { periodDistance: 9, devaluation: 20 },
    { periodDistance: 12, devaluation: 25 },
  ]
  const methods = useForm({ defaultValues: { devaluationData: defaultTable } });
  const { darkmode } = useAuth()
  const { control, reset } = methods;
  const { fields, append, prepend, remove } = useFieldArray({ control, name: "devaluationData" });

  useEffect(() => {
    if (data && data.length > 0) {
      reset({ devaluationData: data });
    }
  }, [ data, reset ]);

  return (
    <div className={styles.cardTableDark} style={{minHeight: '35rem', padding:'2rem'}}>
      <h2 className={styles.subTitle} style={{margin:"0 0 1.5rem 1rem"}}>Discount Ladder Structure</h2>
      <Table>
          <thead>
              <tr className={darkmode ? styles.theaderDark : styles.theader}>
                  <th>Period</th>
                  <th>Devaluation</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
            { fields.map((item, index) => (
                <tr key={item.id} className={`${darkmode ? styles.tableStripsDark : styles.tableStrips} align-middle `}>
                    <td><NumberInput registerName={`devaluationData.${index}.periodDistance`} required={true} defaultValue={item.periodDistance} /></td>
                    <td><NumberInput registerName={`devaluationData.${index}.devaluation`} required={true} defaultValue={item.devaluation} /></td>
                    <td>
                      <button type="button" className="bg-transparent border-0" onClick={() => remove(index)}>
                        <MdDelete style={{color:'#6D9E93', fontSize:'1.1rem'}} />
                      </button>
                    </td>
                </tr>
              ))
            }
          </tbody>
      </Table>
      <button type="button" onClick={() => append({ periodDistance: 0, devaluation: 0 }) } className={styles.editButton}>
        Add
      </button>
    </div>
  );
};
export default DevaluationForm;