import { useState } from "react";
import DealForm from "./DealForm";
import { useEditVentureDeal, usePostDevaluation, usePostVentureDeal, usePostVesting, useVentureDeal } from "../../../query-hooks/ventures-query-hooks/useVentures";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { simpleErrorMessage, simpleSuccessfulMessage } from "../../../Helpers/Helpers";

const DealMutationForm = () => {
  const { ventureDealId } = useParams()
  const [ editMode, setEditMode ] = useState(ventureDealId)
  const navigate = useNavigate()
  const ventureDeal = useVentureDeal(ventureDealId)
  const postVentureDeal = usePostVentureDeal();
  const editVentureDeal = useEditVentureDeal();
  const postDevaluation = usePostDevaluation();
  const postVesting = usePostVesting();
  
  // Mutation Promises
  const postStructures = async (id: string, data: any) => {
    const devaluationPromise = new Promise((resolve, reject) => {
      postDevaluation.mutate(
        { ventureDealId: id, data: data.devaluationData },
        {
          onSuccess: resolve,
          onError: reject,
        }
      );
    });

    const vestingPromise = new Promise((resolve, reject) => {
      postVesting.mutate(
        { ventureDealId: id, data: data.vestingData },
        {
          onSuccess: resolve,
          onError: reject,
        }
      );
    });

    try {
      await Promise.all([devaluationPromise, vestingPromise]);
      simpleSuccessfulMessage(ventureDealId ? 'Deal has been edited successfully' : 'Deal created successfully');
      navigate(`/main/ventures/details/${id}`);
    } catch (err) {
      console.log(err)
      navigate(`/main/ventures/edit-deal/${id}`);
    }
  };

  const onSubmit: SubmitHandler<any> = data => {
    // Check if data.vestingData unlock percentage sum is 100
    const vestingData = data.vestingData
    const sum = vestingData.reduce((acc: number, item: any) => acc + item.unlockPercentage, 0)
    if (sum !== 100) {
      return simpleErrorMessage('Vesting unlock percentage sum must be 100%')
    }

    if (editMode && ventureDealId) {
      editVentureDeal.mutate({ 
        ventureDealId,
        data: {
          activationDate: data.activationDate,
          contractAddress: data.contractAddress,
          contractDate: data.contractDate,
          dealName: data.dealName,
          endDate: data.endDate,
          tgeDate: data.tgeDate,
          totalTokens: data.totalTokens,
          valuationType: data.valuationType
        },
      },
      {
        onSuccess: () => postStructures(ventureDealId, data),
      })
    } else {
        postVentureDeal.mutate({ 
            activationDate: data.activationDate,
            contractAddress: data.contractAddress,
            contractDate: data.contractDate,
            dealName: data.dealName,
            endDate: data.endDate,
            tgeDate: data.tgeDate,
            totalTokens: data.totalTokens,
            valuationType: data.valuationType
        },
        {
          onSuccess: (res) => {
            const newId = res.id;
            setEditMode(newId);
            newId && postStructures(newId, data);
          },
        })
    }
  }

  return <DealForm data={ventureDeal?.data} onSubmit={onSubmit} />
};
export default DealMutationForm;
