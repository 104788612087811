import { useFormContext } from 'react-hook-form'
import styles from './Form.module.scss';
import { useAuth } from '../../../Hooks/useAuth';

interface Props {
  label?: string,
  registerName: string,
  required: boolean,
  defaultDate?: boolean | string,
  disabled?:boolean,
  col?: number
}

const DatePicker = ({ label, registerName, required, defaultDate, disabled }: Props) => {
  const { register, formState: { errors } } = useFormContext()
  const { darkmode } = useAuth()

  const defaultHandler = (value: string | boolean | undefined) => {
    if (typeof value === 'string') {
      return value
    } 
    if (typeof value === 'boolean') {
      if (value === true) {
        return new Date().toISOString().slice(0, 16)
      }
    }
  }

  return (
    <>
      { label &&         
          <label className={ darkmode ? styles.inputLabelDark : styles.inputLabel }>
            { label }
            { required ? <span className='text-danger'> *</span> : <span className='text-xs italic text-secondary font-light'> Optional</span> }
          </label>
      }
      <div className="relative">
        <input 
          type="datetime-local"
          defaultValue={defaultHandler(defaultDate)} 
          className={darkmode ? styles.datetimeDark : styles.datetime} 
          { ...register(registerName, { required: required }) }
          disabled={disabled}
        />
      </div>
      { errors[registerName]?.type === "required" && <span className={styles.formErrorNotice}>This field is required</span>  }
    </>
  );
};

export default DatePicker;
