import React, {useEffect, useState} from "react";
import { useUpdateFundCategoryAndCryptocurrencies } from "../../../../query-hooks/fund-query-hooks/useFund";
import styles from "../AddFundCategoryForm.module.scss";
import {
  SlArrowDown,
  SlArrowRight,
  SlCloudUpload,
  SlPencil,
  SlTrash,
} from "react-icons/sl";
import { percentageEditor } from "../../../../Helpers/Helpers";
import { Button } from "react-bootstrap";
import { HoldingLeaf } from "./HoldingLeaf";
import Swal from "sweetalert2";
import { useCheckRoles } from "../../../../query-hooks/user-query-hooks/useUser";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  FundCategoryHoldingWeightResult,
  FundCategoryWeightResult,
} from "../../../../model/funds/weights/FundWeights";
import { WeightDisplayMode } from "../FundCategoryHoldingsView";

function validateCategory(category: FundCategoryWeightResult) {
  let error = "";
  if (
    !category?.minPercentage ||
    !category?.maxPercentage ||
    !category.aimPercentage
  ) {
    error = "Min, max, aim percentages must be filled out.";
  } else if (category.minPercentage > category.aimPercentage) {
    error = "Min percentage must be less than aim percentage.";
  } else if (category.aimPercentage > category.maxPercentage) {
    error = "Aim percentage must be less than max percentage.";
  }
  if (error != "") {
    Swal.fire({
      icon: "error",
      title: "Validation error",
      text: error,
    });
    return false;
  }
  return true;
}

export function CategoryTree({
  fundId,
  groupIndex,
  categoryIndex,
}: {
  fundId: string;
  groupIndex: number;
  categoryIndex: number;
}) {
  const updateFundCategoryAndWeights: any =
    useUpdateFundCategoryAndCryptocurrencies();

  const [isOpen, setIsOpen] = useState(true);

  const isAdmin = useCheckRoles(["ADMIN", "LEADTRADER", "LEADVENTURES"]);
  const editAllowed = (isAdmin.isSuccess && isAdmin.data);

  const methods = useFormContext();
  const { getValues, setValue, watch } = methods;
  const category = getValues(
    `groups.${groupIndex}.categories.${categoryIndex}`,
  );
  const { fields: holdings } = useFieldArray({
    control: methods.control,
    name: `groups.${groupIndex}.categories.${categoryIndex}.fundCategoryCryptos`,
  });

  const categoryEditId = watch("categoryEditId");
  const editMode = categoryEditId == category.categoryId;

  function setCategoryId(evt: any) {
    evt.stopPropagation();
    if (getValues("categoryEditId") === category?.categoryId) {
      setValue("categoryEditId", "");
      return;
    }
    if (category?.categoryId != null) {
      setValue("categoryEditId", category?.categoryId);
    }
  }

  useEffect(()=>{
    if(!isOpen && categoryEditId == category?.categoryId){
      setValue("categoryEditId", "");
    }
  }, [isOpen])

  async function submit() {
    if (!validateCategory(category)) {
      return;
    }

    const weights = category.fundCategoryCryptos.map(
      (h: FundCategoryHoldingWeightResult) => ({
        ...h,
        categoryId: category.categoryId,
        fundId,
      }),
    );

    await updateFundCategoryAndWeights.mutateAsync({
      ...category,
      fundCategoryCryptos: weights,
      fundId,
    });
    setValue("categoryEditId", "");
  }

  function getInput(propertyName: string) {
    if (category == null) {
      return <></>;
    }
    return (
      <input
        type="number"
        style={{
          maxWidth: "3em",
          marginLeft: "1em",
          padding: "10px",
          height: "1.1rem",
        }}
        className={styles.fundFormInputs}
        {...methods.register(
          `groups.${groupIndex}.categories.${categoryIndex}.${propertyName}`,
          { valueAsNumber: true },
        )}
      ></input>
    );
  }

  const displayMode: WeightDisplayMode = watch("displayMode");

  const iterateDisplayMode = () => {
    const values = Object.values(WeightDisplayMode).filter(
      (value) => typeof value === "number",
    );
    const currentIndex = values.indexOf(displayMode);
    const nextIndex = (currentIndex + 1) % values.length;
    setValue("displayMode", values[nextIndex]);
  };

  if (!category) {
    return;
  }

  return (
    <div className={"w-full"}>
      <div
        className={`w-full d-flex flex-row text-sm font-semibold ${styles.mediumFont}`}
        style={{ justifyContent: "space-between", color: "#6D9E93" }}
      >
        <span onClick={() => setIsOpen(!isOpen)} className="flex items-center">
          {isOpen ? <SlArrowDown /> : <SlArrowRight />}
          {category?.name}
        </span>
        <span
          className="text-muted-foreground"
          style={{ cursor: "pointer" }}
          onClick={iterateDisplayMode}
        >
          {percentageEditor((category?.currPercentage ?? 0) * 100)}
          {category?.categoryId != null && editAllowed ? (
            <Button
              variant={"ghost"}
              style={{ paddingTop: 0, paddingBottom: 0, height: "12pt" }}
              onClick={(evt) => setCategoryId(evt)}
            >
              <SlPencil></SlPencil>
            </Button>
          ) : (
            <span style={{ paddingRight: "2.6em" }}></span>
          )}
        </span>
      </div>
      {categoryEditId != null && categoryEditId === category?.categoryId && (
        <div
          className={"py-1"}
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "1em",
            paddingLeft: "1em",
          }}
        >
          <span>Min {getInput("minPercentage")}</span>
          <span>Aim {getInput("aimPercentage")}</span>
          <span>Max {getInput("maxPercentage")}</span>
          <span style={{ color: "#6D9E93", paddingRight: "1.5em" }}>
            <SlCloudUpload onClick={submit} className={"mx-1"}></SlCloudUpload>
            <SlTrash className={"mx-1"} onClick={setCategoryId}></SlTrash>
          </span>
        </div>
      )}
      {isOpen && (
        <div style={{ paddingLeft: "1em" }}>
          {holdings.map((h, index) => (
            <HoldingLeaf
              groupIndex={groupIndex}
              categoryIndex={categoryIndex}
              holdingIndex={index}
              editMode={editMode}
              key={`holding-${index}`}
              iterateDisplayMode={iterateDisplayMode}
              displayMode={displayMode}
            />
          ))}
        </div>
      )}
    </div>
  );
}
